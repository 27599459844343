@import url(bootstrap.min.css);

/*
hiding the reCaptcha badge from screen
also shifting it outside the viewport so that it does not block any click event on screen
 */
.grecaptcha-badge {
  visibility: hidden !important;
  right: -260px !important;
}

* {
  outline: none;
  margin: 0;
  padding: 0;
}
html {
  height: 100%;
}
input[type='text']::-ms-clear,
input[type='tel']::-ms-clear {
  display: none;
}
input,
.form-group .form-control {
  box-shadow: none;
}
.titelliumRegular,
.raceVideos h3 {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
}
.titWebSemiBold,
.btnRed {
  font-family: 'Titillium Web';
  font-weight: 600;
}
body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #666;
  min-height: 100%;
  position: relative;
}
.mainLogo .mobileHide {
  display: block;
}
.mainLogo .mobileShow {
  display: none;
}
#app-container {
  padding-bottom: 80px; /*footer height*/
}
body.activeMenu {
  overflow: hidden;
}
.tabs.active {
  display: block;
}
.clr {
  clear: both;
}
.fancybox-skin {
  background: #fff;
  color: #666;
  border-radius: 10px;
}
input.error,
select.error {
  border: 1px solid #ec1c24;
}
.customCheckbox {
  display: inline-block;
  position: relative;
}
.customCheckbox input[type='checkbox'] {
  opacity: 0;
  margin: 0 0;
}
.customCheckbox span {
  display: block;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #c4c8cc;
  border-radius: 3px;
  cursor: pointer;
  background-position: 1000px;
}
.customCheckbox span.checked {
  background-position: -31px -19px;
  border: 1px solid transparent;
}
.customCheckbox span.disabled,
.customCheckbox .horseList .scratchHorse span.icon-checkBox,
.horseList .scratchHorse .customCheckbox span.icon-checkBox {
  background-color: #e8e8e8;
  opacity: 0.5;
}
.defaultSelect {
  border: 1px solid #ccc;
  height: 30px;
  border-radius: 2px;
  position: relative;
  width: 100%;
  padding: 0 0 0 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  line-height: 20px;
  border: 1px solid #dcdcdc;
  background-position: right 10px center;
  z-index: 2;
  background: url('../images/arrowDown@2x.png') no-repeat white;
  background-size: 11px 6px;
  background-position: top 50% right 10px;
}
select::-ms-expand {
  display: none;
}
.noTextSelect {
  -webkit-touch-callout: none;
  /* iOS Safari */

  -webkit-user-select: none;
  /* Chrome/Safari/Opera */

  -khtml-user-select: none;
  /* Konqueror */

  -moz-user-select: none;
  /* Firefox */

  -ms-user-select: none;
  /* Internet Explorer/Edge */

  user-select: none;
  /* Non-prefixed version, currently
     not supported by any browser */
}
.listInline:after,
.listInline:before {
  display: table;
  content: ' ';
}
.listInline:after {
  clear: both;
}
.listInline li {
  float: left;
}
.blockElement {
  display: block;
}
.btns {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  padding: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  text-decoration: none;
  border: 0;
  transition: background 0.3s linear;
}
.btns:visited,
.btns:focus {
  text-decoration: none;
}
.btnO:focus,
.btnO:hover,
.btnO {
  background: #fff;
  color: #666;
  text-decoration: none;
  border: 1px solid #dedede;
}
.btnBorder {
  background: #fff;
  border: 1px solid #005bf0;
  color: #005bf0;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  padding: 7px 20px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
.btnBorder:hover,
.btnBorder:focus {
  background: #005bf0;
  color: #fff;
  text-decoration: none;
}
.btnBlue:focus,
.btnBlue {
  color: #fff;
  background: #005bf0;
}
.btnBlue:focus:hover,
.btnBlue:hover {
  text-decoration: none;
  background: #3079f0;
  color: #fff;
}
.btnGreen:focus,
.btnGreen {
  background: #66aa33;
  color: #fff;
  border: 0;
}
.btnGreen:focus:hover,
.btnGreen:hover {
  text-decoration: none;
  color: #fff;
  background: #64a030;
}
.btnRed {
  background: #ec1c24;
  color: #fff;
  font-size: 16px;
  line-height: 18px;
  padding: 5px;
}
.btnRed:hover {
  text-decoration: none;
  color: #fff;
  background: #d7030b;
}
.btnBlueBorder {
  border: 1px solid #005bf0;
  color: #005bf0;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  padding: 0 10px;
  background: #fff;
}
.dataTable {
  margin: 0;
  list-style-type: none;
  display: table;
  width: 100%;
}
.dataTable .dataRow {
  display: table-row;
}
.dataTable .dataCell {
  display: table-cell;
}
.textWarning {
  color: #ec1c24;
}
.textLive,
.textSuccess,
.textGreen {
  color: #6ba444;
}
.ttFont {
  font-family: 'Titillium Web', sans-serif;
}
[class^='icon-'],
[class*='icon-'] {
  background-image: url('../images/sprite.png');
  display: inline-block;
  background-repeat: no-repeat;
}
.icon-fb {
  width: 7px;
  height: 20px;
  background-position: -40px -182px;
}
.icon-twit {
  width: 12px;
  height: 20px;
  background-position: -40px -156px;
}
.icon-gPlus {
  height: 20px;
  width: 12px;
  background-position: -40px -135px;
}
.icon-mails {
  height: 20px;
  width: 12px;
  background-position: -40px -112px;
}
.icon-share {
  width: 13px;
  height: 14px;
  background-position: -58px -97px;
}
.icon-call {
  height: 15px;
  width: 15px;
  background-position: -59px -21px;
}
.icon-closeBlack {
  width: 13px;
  height: 13px;
  background-position: -58px -2px;
}
.icon-help {
  width: 15px;
  height: 15px;
  background-position: -20px 0;
}
.icon-bell {
  width: 18px;
  height: 19px;
  background-position: 0 -207px;
}
.icon-close {
  height: 12px;
  width: 13px;
  display: inline-block;
}
.icon-next,
.icon-prev {
  display: inline-block;
  height: 40px;
  width: 25px;
}
.icon-prev {
  background-position: 2px -36px;
}
.icon-next {
  background-position: 2px -81px;
}
.icon-popout {
  width: 16px;
  height: 16px;
  background-position: 0 -17px;
}
.icon-listUp {
  width: 12px;
  height: 8px;
  background-position: -22px -216px;
}
.icon-listDown {
  width: 12px;
  height: 8px;
  background-position: -22px -208px;
}
.icon-hotstate {
  width: 50px;
  height: 12px;
  background-position: 0 -252px;
}
.icon-xbets {
  width: 80px;
  height: 16px;
  background-position: 0 -305px;
}
.icon-tooltip {
  width: 8px;
  height: 15px;
  background-position: -40px 0;
}
.icon-infoBlue {
  height: 17px;
  width: 17px;
  background-position: -33px -268px;
}
.icon-left {
  background-position: 0 -230px;
}
.icon-right {
  background-position: -15px -230px;
}
.arrowDown,
.arrowUp {
  display: inline-block;
  width: 15px;
  height: 8px;
  right: 10px;
  background: url('../images/arrowDown@2x.png') no-repeat;
  background-size: 100% 100%;
}
.arrowUp {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.icon-downArrow {
  width: 7px;
  height: 11px;
  background-position: -30px -82px;
}
.icon-rightArrow {
  height: 7px;
  width: 11px;
  background-position: -30px -97px;
}
.icon-blackArrow {
  width: 11px;
  height: 20px;
  background-position: -58px -116px;
}
.icon-watchVideo {
  width: 65px;
  height: 65px;
  background-position: 0 -330px;
}
.pipeSpacer {
  content: '|';
}
[class^='iconW'],
[class*='iconW'] {
  background-image: url('../images/weatherSprite.png');
  width: 30px;
  height: 30px;
  display: inline-block;
  vertical-align: top;
  margin-left: -5px;
}
.iconW01,
.iconW02,
.iconW03,
.iconW04 {
  background-position: 0 0;
}
.iconW05 {
  background-position: -180 0;
}
.iconW06 {
  background-position: -210px 0;
}
.iconW07 {
  background-position: -240px 0;
}
.iconW08 {
  background-position: -270px 0;
}
.iconW09 {
  background-position: 0 -30px;
}
.iconW10 {
  background-position: -30px -30px;
}
.iconW11 {
  background-position: -60px -30px;
}
.iconW12 {
  background-position: -90px -30px;
}
.iconW13 {
  background-position: -120px -30px;
}
.iconW14 {
  background-position: -150px -30px;
}
.iconW15 {
  background-position: -180p -30px;
}
.iconW16 {
  background-position: -210px -30px;
}
.iconW17 {
  background-position: -240px -30px;
}
.iconW18 {
  background-position: -270px -30px;
}
.iconW19 {
  background-position: 0 -60px;
}
.iconW20 {
  background-position: -30px -60px;
}
.iconW21 {
  background-position: -60px -60px;
}
.iconW22 {
  background-position: -90px -60px;
}
.iconW23,
.iconW24 {
  background-position: -120px -60px;
}
.iconW25 {
  background-position: -180px -60px;
}
.iconW26 {
  background-position: -210px -60px;
}
.iconW27 {
  background-position: -240px -60px;
}
.iconW28 {
  background-position: -270px -60px;
}
.iconW29 {
  background-position: 0 -90px;
}
.iconW30 {
  background-position: -30px -90px;
}
.iconW31 {
  background-position: -60px -90px;
}
.iconW32 {
  background-position: -90px -90px;
}
.iconW33 {
  background-position: -120px -90px;
}
.iconW34 {
  background-position: -150px -90px;
}
.iconW35 {
  background-position: -180px -90px;
}
.iconW36 {
  background-position: -210px -90px;
}
.iconW37,
.iconW38 {
  background-position: -240px -90px;
}
.iconW39 {
  background-position: 0 -120px;
}
.iconW40 {
  background-position: -30px -120px;
}
.iconW41 {
  background-position: -60px -120px;
}
.iconW42 {
  background-position: -90px -120px;
}
.iconW43 {
  background-position: -120px -120px;
}
.iconW44 {
  background-position: -150px -120px;
}
.iconW45 {
  background-position: -180px -120px;
}
.iconW46 {
  background-position: -210px -120px;
}
.iconW47 {
  background-position: -240px -120px;
}
.toolTipWrapper {
  display: inline-block;
  position: relative;
}
.toolTipWrapper .toolTipIcon {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: #7d7d7d;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
}
.toolTipWrapper .toolTipIcon .icon-tooltip {
  margin-left: 5px;
  margin-top: 2px;
}
.toolTipWrapper .toolTipContent {
  font-size: 14px;
  line-height: 18px;
  position: absolute;
  bottom: 105%;
  padding: 5px;
  border: 1px solid #ccc;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  background: #fff;
  width: 200px;
  left: 50%;
  margin-left: -100px;
  display: none;
  z-index: 1000;
}
.toolTipWrapper .toolTipContent:before {
  position: absolute;
  content: '';
  display: inline-block;
  left: 50%;
  margin-left: -10px;
  bottom: -10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #7d7d7d;
}
.horseName {
  font-size: 15px;
  line-height: 18px;
  color: #333333;
}
.toolTip {
  position: absolute;
  bottom: 100%;
  padding: 4px 10px;
  background: #fff;
  border: 1px solid #bbb;
  white-space: nowrap;
  margin-bottom: 10px;
  border-radius: 3px;
  text-align: center;
  font-size: 12px;
  cursor: text;
  display: none;
  color: #666;
}
.toolTip:after {
  content: '';
  display: block;
  position: absolute;
  border-top: 7px solid #fff;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  top: 100%;
  left: 50%;
  margin-left: -7px;
  z-index: 3;
}
.toolTip:before {
  margin-top: 1px;
  content: '';
  display: block;
  position: absolute;
  border-top: 7px solid #bbb;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  top: 100%;
  left: 50%;
  margin-left: -7px;
  z-index: 2;
}
.loaderImg {
  display: inline-block;
  background-image: url(../images/loader.gif);
  height: 112px;
  width: 202px;
}
.icon-info {
  background-position: -33px -268px;
  height: 17px;
  width: 17px;
}
.blackOverLay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9991;
}

.mobTodaysTrack,
.headerMobLoginBtn {
  display: none;
}
.dashboardContent,
.bannerWrapper {
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
}
/*header style starts*/
.hamburgerBtnBox {
  width: 55px;
  position: relative;
  vertical-align: middle;
  float: left;
}
.hamburgerBtnBox.active {
  background: #eceff5;
}
.hamburgerBtnBox .hamburgerBtn {
  padding: 25px 0 24px;
  cursor: pointer;
}
.hamburgerBtnBox .hamburgerBtn .arrowUp:before,
.hamburgerBtnBox .hamburgerBtn .arrowUp:after,
.hamburgerBtnBox .hamburgerBtn .hamburger:before,
.hamburgerBtnBox .hamburgerBtn .hamburger:after,
.hamburgerBtnBox .hamburgerBtn .hamburger {
  display: block;
  margin: 0 auto;
  height: 1px;
  width: 20px;
  background: #005bf0;
  content: ' ';
}
.hamburgerBtnBox .hamburgerBtn .arrowUp {
  background: none;
  width: auto;
  height: auto;
  display: block;
}
.hamburgerBtnBox .hamburgerBtn .arrowUp:before {
  top: 0;
  transform: rotate(-45deg);
}
.hamburgerBtnBox .hamburgerBtn .arrowUp:after {
  top: -1px;
  transform: rotate(45deg);
}
.hamburgerBtnBox .hamburgerBtn span:before {
  position: relative;
  top: -7px;
}
.hamburgerBtnBox .hamburgerBtn span:after {
  position: relative;
  top: 6px;
}
.hamburgerBtnBox.active .hamburgerBtn span {
  background: transparent;
}
.hamburgerBtnBox.active .hamburgerBtn span:before,
.hamburgerBtnBox.active .hamburgerBtn span:after {
  top: 0;
  background: #000;
}
.hamburgerBtnBox.active .hamburgerBtn span:before {
  transform: rotate(-45deg);
}
.hamburgerBtnBox.active .hamburgerBtn span:after {
  transform: rotate(45deg);
}
.mobileHamburgerBtn {
  display: none;
}
.header {
  border-bottom: 1px solid #eee;
  border-left: 0;
  border-right: 0;
  margin-bottom: 0;
  width: 100%;
  position: fixed;
  background: #fff;
  z-index: 10;
  height: 50px;
}
.mainLogo {
  display: inline-block;
  vertical-align: middle;
  height: 50px;
}
div.mainLogo {
  float: left;
}
.mainLogo img {
  width: 174px;
  height: 50px;
  display: block;
}
.memberDropdown.active > a,
.notificationsWrap.active > a,
.helpWrap.active > a,
.hamburgerBtnBox.active > a {
  background: #f3f3f3;
}
.memberDropdown.active {
  background: #eceff5;
}
.memberDropdown.active .menuBtn .placeholder {
  background: #d9dee8;
  border: 1px solid #b5c1db;
}
.defaultDropList {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  z-index: 201;
  text-align: left;
  border: 1px solid #ddd;
  top: 50px;
}
.defaultDropList li {
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
}
.defaultDropList li a {
  display: block;
  color: #000;
  padding: 0 10px;
  font-size: 14px;
  border-top: 1px solid #eee;
  text-decoration: none;
}
.defaultDropList li a:hover {
  background: #eceff5;
  color: #005bf0;
}
.defaultDropList li:first-child a {
  border: 0;
}
.helpDropdown,
.navDropdown {
  width: 302px;
  border: 1px solid #ddd;
  top: 50px;
}
.helpDropdown {
  left: -1px;
}
.helpDropdown li a {
  padding: 15px;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  font-family: Titillium Web, sans-serif;
}
.menuBtn.open .helpDropdown {
  display: block;
}
.navDropdown li.betsLinkList {
  border-top: 1px solid #eee;
  text-align: center;
  padding: 24px;
}
.navDropdown li.betsLinkList a {
  text-decoration: underline;
  font-family: Arial;
  font-size: 14px;
  font-weight: 400;
  color: #005bf0;
  display: inline;
  border: 0;
}
.navDropdown li.betsLinkList a:hover {
  background: #fff;
}
.navDropdown.defaultDropList li a {
  font-size: 14px;
  line-height: 16px;
  padding: 15px;
  font-family: Titillium Web, sans-serif;
  font-weight: 400;
}
.helpWrap {
  position: relative;
  text-align: center;
  float: left;
  cursor: pointer;
}
.helpWrap .helpArrow {
  display: inline-block;
  vertical-align: middle;
  padding: 6px 10px;
}
.helpWrap .helpArrow i {
  margin: 0;
}

.helpWrap .menuBtn {
  text-decoration: none;
  color: #000;
  display: block;
  line-height: 18px;
  padding: 10px 0;
  cursor: pointer;
}
.helpWrap .menuBtn.open {
  background: #eceff5;
}
.helpWrap .menuBtn .placeholder {
  display: inline-block;
  vertical-align: middle;
  line-height: 30px;
  font-size: 16px;
  text-decoration: none;
  color: #333;
  font-family: 'Titillium Web', sans-serif;
  cursor: pointer;
}
.notificationsWrap {
  float: left;
  width: 60px;
  border-left: 1px solid #e9e9e9;
  text-align: center;
  position: relative;
}
.notificationsWrap > span {
  display: block;
  padding: 19px 0;
}
.notificationsWrap > span .bellNotification {
  display: block;
  position: relative;
}
.notificationsWrap .bellNotification {
  padding: 19px 0;
}
.notificationsWrap .notifyNumber {
  position: absolute;
  display: inline-block;
  z-index: 1;
  top: 13px;
  color: #fff;
  right: 15px;
  font-size: 10px;
  width: 20px;
  text-align: center;
}
.notificationsWrap .notifyNumber:before {
  content: '';
  display: block;
  height: 17px;
  width: 17px;
  background: #ec1c24;
  position: absolute;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  z-index: -1;
  margin-top: -9px;
  margin-left: -9px;
}
.notificationsWrap .notifyDropdown {
  position: absolute;
  top: 100%;
  width: 278px;
  background: #fff;
  z-index: 205;
  padding: 10px 0 0 10px;
  box-shadow: 0 0 27px rgba(0, 0, 0, 0.14);
}
.notificationsWrap .notifyDropHeader {
  border-bottom: 1px solid #e9e9e9;
  padding: 0 10px 5px 0;
}
.notificationsWrap .notifyTime,
.notificationsWrap .notifyRemaining {
  float: right;
  font-size: 11px;
  color: #888;
}
.notificationsWrap .notifyRemaining {
  display: block;
  line-height: 22px;
}
.notificationsWrap .notifyRemaining i {
  width: 16px;
  height: 16px;
  color: #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background: #ec1c24;
  display: inline-block;
  text-align: center;
  top: 0;
  line-height: 16px;
  font-size: 10px;
  margin-left: 5px;
}
.notificationsWrap .notifyTitle {
  float: left;
  font-size: 16px;
  color: #ec1c24;
}
.notificationsWrap .notifyTime {
  font-size: 12px;
  color: #aaa;
}
.notificationsWrap .moreNotify a {
  float: left;
}
.notificationsWrap .moreNotify .moreNotifyLink {
  font-size: 12px;
  color: #005bf0;
  text-decoration: underline;
}
.notificationsWrap .moreNotify .btnGreen {
  width: 75px;
  padding: 3px 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
.notificationsWrap .notificationsList {
  margin: 0;
}
.notificationsWrap .notificationsList li {
  padding: 15px 10px;
  border-bottom: 1px solid #e9e9e9;
}
.notificationsWrap .notificationsList p {
  font-size: 13px;
  margin-bottom: 5px;
  color: #7b7b7b;
}
.notificationsWrap .notificationsList .lastNotifyList {
  border: 0;
  padding: 14px 0;
  text-align: center;
}
.notificationsWrap .notificationsList .lastNotifyList a {
  font-size: 13px;
  text-decoration: underline;
  color: #005aee;
}
.depositWrap {
  padding: 9px 0;
  text-align: center;
  float: left;
}
.depositWrap > a {
  width: 95px;
  font-size: 12px;
  padding: 7px 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  font-family: Titillium Web, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}
.headBalance {
  float: left;
  text-align: left;
  padding: 7px 0;
  font-family: 'Titillium Web', Arial, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}
.breadcrumb > a.active {
  font-weight: bold;
}
.headBalance .userBalance {
  display: block;
  font-weight: bold;
  color: #005bf0;
  font-size: 16px;
  line-height: 16px;
}
.headBalance .userBalanceLabel {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}
.mainLogo svg .cls-1 {
  fill: #ec1c24;
}
.mainLogo svg .cls-2,
.mainLogo svg .cls-3,
.mainLogo svg .cls-4 {
  fill-rule: evenodd;
}
.mainLogo svg .cls-3 {
  fill: #ae060d;
}
.mainLogo svg .cls-4 {
  fill: #fff;
}
.memberDropdown {
  text-align: center;
  position: relative;
  float: left;
}
.memberDropdown > .menuBtn {
  color: #000;
  padding: 5px 0;
  display: block;
}
.memberDropdown > .menuBtn .placeholder,
.mobileMenuDropdown .loginName .placeholder {
  background: #eee;
  border: 1px solid #ddd;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 50%;
  margin: auto;
  text-align: center;
  vertical-align: middle;
  line-height: 40px;
  font-family: Titillium Web, sans-serif;
}
.memberArrowDown,
.memberArrowUp {
  margin-left: 10px;
  display: inline-block;
  width: 11px;
  height: 6px;
  right: 10px;
  background: url('../images/arrowDown@2x.png') no-repeat;
  background-size: 100% 100%;
}
.memberArrowUp {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.memberDropdown .memberDropdownList {
  display: none;
  right: 0;
  width: 216px;
  left: auto;
}
.memberDropdownList .userEmailId {
  padding: 15px;
  font-size: 14px;
  line-height: 16px;
  font-family: Titillium Web, sans-serif;
  font-weight: 400;
  color: #666;
}
.memberDropdown .memberDropdownList li a {
  padding: 15px;
  line-height: 16px;
  font-family: Titillium Web, sans-serif;
  font-weight: normal;
}
.memberDropdown .memberDropdownList li a.logout {
  color: #ec1c24;
}
.memberDropdown .memberDropdownList li a.logout:hover {
  color: #ec1c24;
}
.headerLogin {
  padding: 10px 10px 0 0;
  display: inline-block;
  margin-right: 6px;
  vertical-align: top;
}
.headerLogin > .form-group {
  display: inline-block;
  margin-right: 6px;
  margin-bottom: 0;
  vertical-align: top;
  width: 170px;
}
.headerLogin > .form-group input {
  padding: 0 0 0 15px;
  box-shadow: none;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  font-size: 13px;
  height: 30px;
  margin-bottom: 3px;
}
.headerLogin > .form-group a {
  display: block;
  font-weight: 400;
  font-size: 11px;
  color: #7b7b7b;
  text-decoration: underline;
  margin-bottom: 2px;
}
.headerLogin .headerSelect {
  width: 160px;
}
.headerRegisterBtn,
.headerLoginBtn {
  width: 91px;
  font-size: 16px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
}
.headerLoginBtn {
  height: 44px;
  line-height: 44px;
  padding: 0;
}
.headerRegisterBtn {
  margin-top: 10px;
}
header .classicDrfBetsLink,
header .helpWrap .menuBtn,
header .headBalance,
header .depositWrap,
header .memberDropdown > .menuBtn {
  padding-left: 10px;
  padding-right: 10px; /*horizontal spance*/
}
header .helpWrap .menuBtn {
  padding-right: 0;
}
/*header style ends*/

/*main content starts*/

.rightSidebarWrap,
.raceInfoLeftWrap {
  float: left;
}
.raceInfoLeftWrap {
  border-right: 1px solid #e9e9e9;
  width: 850px;
  min-height: 300px;
}
.rightSidebarWrap {
  padding: 10px 0;
  width: 318px;
  border-left: 1px solid #e9e9e9;
  margin-left: -1px;
}
.rightTabsWrap {
  padding: 0 10px;
}
.redSectionTitle {
  font-size: 24px;
  font-weight: 600;
  color: #dd2222;
  margin-top: 10px;
}
.raceBetNowBtn {
  float: right;
  width: 84px;
  padding: 7px 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
.spinner {
  text-align: center;
  vertical-align: middle;
}
/*main content ends*/
/*right sidebar starts*/

.rightSidebar {
  width: 320px;
}
.sidebarRedSubTitle {
  color: #dd2222;
  font-size: 20px;
  padding-left: 20px;
  margin: 5px 0;
}
.rightTabsContent .tabContent {
  padding-bottom: 10px;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 10px;
}
/*right sidebar ends*/

/*mobile header starts*/

.mobileLogo {
  margin: 14px 0;
}
.menuOpen {
  display: block;
  width: 45px;
  border-right: 1px solid #ddd;
  padding: 21px 0;
}
.menuOpen span:before,
.menuOpen span:after,
.menuOpen span {
  display: block;
  margin: 0 auto;
  height: 1px;
  width: 16px;
  background: #005bf0;
  content: ' ';
}
.menuOpen span:before,
.menuOpen span:after {
  height: 1px;
}
.menuOpen span:after {
  position: relative;
  bottom: -4px;
}
.menuOpen span:before {
  position: relative;
  top: -5px;
}
.mobileHeader {
  position: relative;
}
.mobileMenubar {
  float: left;
}
.mobileMenubar .loggedUser {
  background: #f5f5f5;
  padding: 0;
  margin-bottom: 10px;
}
.mobileMenubar .loggedUser h4 {
  margin: 0;
  line-height: 48px;
  font-size: 16px;
  line-height: 18px;
  color: #000;
  padding: 10px 15px;
}
.mobileMenubar .loggedUser h4 .placeholder {
  margin: 0 10px 0 0;
}
.mobileMenubar .loggedUser h4 .icon-DropArrow {
  float: right;
  margin: 15px 20px 0 0;
  width: 13px;
  display: inline-block;
  height: 13px;
  background-position: -22px -203px;
}
.mobileMenubar .loggedUser li.logoutLink a {
  color: #ec1c24;
}
.mobileMenubar .mobileLogo a {
  display: block;
  margin: 0 auto;
  width: 80px;
  height: 16px;
  background: url('../images/logo@2x.png') no-repeat;
  background-size: 100% 100%;
}
.mobileMenubar .userBalanceWrap {
  border-top: 1px solid #ddd;
  padding: 5px 0;
}
.mobileMenubar .userBalanceWrap > div {
  width: 50%;
  text-align: center;
}
.mobileMenubar .userBalanceWrap > div:first-child {
}
.mobileMenubar .userBalanceWrap > div .userBalance {
  text-decoration: none;
  font-size: 18px;
  font-family: Titillium Web, sans-serif;
  font-weight: 600;
  color: #3079f0;
}
.mobileMenubar .userBalanceWrap > div .userBalanceLabel {
  color: #888888;
  font-size: 13px;
  font-family: Arial;
  display: block;
}
.mobileMenubar .userBalanceWrap .depositBtnWrap .depositBtn {
  font-size: 12px;
  padding: 0 15px;
  height: 44px;
  line-height: 44px;
  width: 120px;
  text-transform: uppercase;
  font-family: Titillium Web, sans-serif;
  font-weight: 600;
}
.mobileMenubar .mobileMenuDropdown {
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 250;
  background: #fff;
  border: 1px solid #ddd;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.mobileMenubar .mobileMenuDropdown .closePopup {
  width: 45px;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 999;
  margin: auto;
  box-shadow: 4px 0px 8px 1px #ddd;
}
.mobileMenubar .mobileMenuDropdown .popupContent {
  padding-top: 45px;
  margin-left: 45px;
}

.mobileMenubar .mobileMenuDropdown .popupMobileLogo {
  background: #fafafa;
  padding: 13px 0;
  border-bottom: 1px solid #ddd;
  text-align: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
}
.popupMobileLogo .mobileLogoSvgWrap {
  margin-left: -45px;
  /*center the logo */
}
.mobileMenubar .mobileMenuDropdown.active {
  left: 0;
}
.mobileMenubar .mobileMenuDropdown .userLinks {
  padding: 0;
  margin-bottom: 0;
}
.mobileMenubar .mobileMenuDropdown .userLinks > li {
  padding: 0;
}
.mobileMenubar .mobileMenuDropdown .btnBlue,
.mobileMenubar .mobileMenuDropdown .btnBorder {
  display: block;
  margin: 0 20px 15px;
  font-size: 18px;
  padding: 8px 0;
}
.mobileMenubar .mobileMenuDropdown .headerlinkList {
  margin: 0 0 10px 0;
}
.mobileMenubar .mobileMenuDropdown .headerlinkList > li,
.mobileMenubar .mobileMenuDropdown ul li {
  padding: 13px 9px;
  border-top: 1px solid #eee;
  background: #f5f5f5;
}
.mobileMenubar .mobileMenuDropdown ul li:last-child {
  border-bottom: 1px solid #eee;
}
.mobileMenubar .mobileMenuDropdown ul.userLinks .active.profileDropArrow .profileLinkWrap {
  border-bottom: 1px solid #ddd;
}
.mobileMenubar .mobileMenuDropdown .headerlinkList > li.helpMenu {
  background: #eceff5;
  overflow: hidden;
  margin: 0 0 10px 0;
}
.mobileMenubar .mobileMenuDropdown .headerlinkList > li > a,
.mobileMenubar .mobileMenuDropdown ul li a {
  font-size: 16px;
  color: #333;
  display: block;
  position: relative;
  text-decoration: none;
}
.mobileMenubar .mobileMenuDropdown .headerlinkList > li .helpDrop:before,
.mobileMenubar .mobileMenuDropdown .headerlinkList > li .helpDrop:after {
  content: '';
  display: block;
  position: absolute;
  right: 28px;
  top: 50%;
  margin-top: -1px;
  border-top: 7px solid #005bf0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}
.mobileMenubar .mobileMenuDropdown .headerlinkList > li .helpDrop:after {
  margin-top: -3px;
  border-top: 7px solid #fff;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}
.headerlinkList .icon-helpDropArrow {
  margin-top: 15px;
}
.mobileMenubar .mobileMenuDropdown .headerlinkList > li .mobilehHelpList {
  padding: 0;
  display: none;
  margin: 0 -15px;
}
.mobileMenubar .mobileMenuDropdown .headerlinkList > li .mobilehHelpList li {
  padding: 13px 9px;
  border-top: 1px solid #ddd;
}
.mobileMenubar .mobileMenuDropdown .headerlinkList > li .mobilehHelpList li a {
  color: #333;
  font-size: 16px;
  font-family: Titillium Web, sans-serif;
  text-decoration: none;
}
.mobileMenubar.active .mobileMenuDropdown {
  left: 0;
}
.mobileMenubar.active .menuOpen span {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
  height: 0;
}
.mobileMenubar.active .menuOpen span:before {
  top: 1px;
  transform: rotate(45deg);
}
.mobileMenubar.active .menuOpen span:after {
  bottom: 0;
  transform: rotate(-45deg);
}
/*mobile header ends*/

.errorMsg {
  background: #fbfbfb;
  font-family: 'Titillium Web', Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  color: #888888;
  text-align: center;
  padding: 10px 70px;
  margin: 20px 0;
}
/*404 page*/

.fixedBottom {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}
.notFoundWrap {
  position: absolute;
  width: 430px;
  left: 50%;
  margin-left: -215px;
  top: 50%;
  margin-top: -70px;
}
.notFoundWrap h4 {
  color: #be2025;
  font-size: 25px;
  margin: 20px 0 10px;
}
.notFoundWrap p {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
}
.notFoundWrap .btn {
  border: 1px solid #eee;
  min-width: 102px;
  padding: 4px 0;
}
.yellowBox,
.whiteBox {
  display: inline-block;
  vertical-align: top;
  font-size: 30px;
  color: #000;
  background: #e7e630;
  padding: 4px 17px;
  margin: 0 4px;
  border: 1px solid #e7e630;
  border-radius: 4px;
}
.whiteBox {
  background: #fff;
  border: 1px solid #000;
}
.center-block {
  float: none;
}
/* withdraw */
.paymentMethodWrap {
  padding-bottom: 30px;
}
.paymentMethodWrap.creditDebitCardWrap {
  padding-bottom: 0;
}
.paymentMethodWrap .pageTitle {
  padding: 10px 0 30px 0;
}
.paymentMethodWrap .pageTitle h2 {
  font-size: 26px;
  font-weight: 600;
  color: #333;
}
.paymentMethodWrap .pageTitle .addLink {
  margin: 20px 0 0 0;
}
.paymentMethodWrap .pageTitle .addLink a {
  font-family: Titillium Web, sans-serif;
  font-size: 15px;
  vertical-align: middle;
  cursor: pointer;
}
.paymentMethods li.paymentMethod {
  padding: 13px 11px;
  box-shadow: 0px 0px 14px 1px #ededed;
  margin-bottom: 15px;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid #fff;
}
.chequeIssue {
  font-size: 16px;
  color: #001248;
  font-weight: 600;
}
.paymentMethods li.paymentMethod.active {
  border: 1px solid #66aa33;
}
.paymentMethods li.paymentMethod.active i.radioIcon.active {
  background-position: -36px -403px;
}
.paymentMethods .paymentRadioBtn {
  position: relative;
}
.paymentMethods .paymentRadioBtn input {
  opacity: 0;
  width: 30px;
}
.paymentMethods .paymentRadioBtn i.radioIcon {
  background: url('../images/sprite.png') no-repeat 0 -403px;
  display: inline-block;
  position: absolute;
  width: 30px;
  height: 32px;
  top: 0;
  left: 0;
  z-index: 1;
}
.paymentMethods .paymentMethodType {
  margin-left: 35px;
}
.paymentMethods .paymentMethodType .methodName span.xpressBetTxt {
  color: #001248;
  font-size: 16px;
  font-weight: 600;
  vertical-align: middle;
  margin-right: 6px;
}
.paymentMethods .paymentMethodType .methodName i.icon {
  background-image: url('../images/sprite.png');
  display: inline-block;
}
.paymentMethods .paymentMethodType .methodName .xpressFundMethod {
  background-position: 0 -437px;
  width: 120px;
  height: 25px;
}
.paymentMethods .paymentMethodType .methodName .cardPaymentMethod {
  background-position: -1px -563px;
  width: 120px;
  height: 34px;
}
.paymentMethods .paymentMethodType .methodName .payNearMeMethod {
  background-position: 0 -598px;
  width: 120px;
  height: 30px;
}
.paymentMethods .paymentMethodType .methodName .xpressbetMethod {
  background-position: 0 -466px;
  width: 120px;
  height: 25px;
  vertical-align: middle;
}
.paymentMethods .paymentMethodType .methodName .paypalMethod {
  background-position: 0 -494px;
  width: 120px;
  height: 28px;
}
.paymentMethods .paymentMethodType .methodName .netellerMethod {
  background-position: 0 -528px;
  width: 120px;
  height: 28px;
}
.paymentMethods .paymentMethodType .methodDesc {
  font-family: Arial;
  font-size: 14px;
  color: #666666;
  margin-bottom: 0;
  padding-right: 30px;
}
.paymentMethods .cardPaymentActions {
  margin-top: 15px;
}
.paymentMethods .cardPaymentActions input {
  width: 102px;
  margin-right: 20px;
  padding: 6px 12px;
  font-size: 14px;
}
.paymentMethods .cardPaymentActions .btnO.btn {
  width: 102px;
  margin: 0 5px;
}
.cardPaymentActions .btnO.btn.btnBlue,
.cardPaymentActions .btnO.btn.btnBlue:focus {
  color: #fff;
  background: #005bf0;
  height: 30px;
  line-height: 30px;
  padding: 0;
  border-radius: 0;
  border: none;
  font-size: 16px;
  font-family: Titillium Web, sans-serif;
}
.cardPaymentActions .btnO.btn {
  background: #fff;
  text-decoration: none;
  border: 1px solid #dedede;
  border-radius: 0;
  height: 30px;
  line-height: 30px;
  padding: 0;
  color: #888888;
}
.additionalMethods .additionMethodTitle {
  color: #333333;
  font-size: 16px;
  font-weight: 600;
}
.additionalMethods ul.additionalMethodWrapper li.paymentMethod {
  border: none;
  border-bottom: 1px solid #e1e1e1;
  padding: 12px 0 20px 0;
}
.additionalMethods ul.additionalMethodWrapper li.paymentMethod .paymentMethodType .methodName .icon {
  background-image: url('../images/sprite.png');
  display: inline-block;
}
.additionalMethods ul.additionalMethodWrapper li.paymentMethod .paymentMethodType .methodName .additionalXpressBet {
  background-position: 0 -468px;
  width: 112px;
  height: 20px;
  vertical-align: middle;
}
.additionalMethods ul.additionalMethodWrapper li.paymentMethod .paymentMethodType .methodName .additionalCallMethod {
  background-position: -78px -405px;
  width: 25px;
  height: 24px;
  vertical-align: middle;
}
.additionalMethods ul.additionalMethodWrapper li.paymentMethod .paymentMethodType .methodName .blueBtn {
  background: #0052ad;
  color: #fff;
  height: 25px;
  line-height: 25px;
  padding: 0 8px;
  display: inline-block;
}
.additionalMethods ul.additionalMethodWrapper li.paymentMethod .paymentMethodType .methodName span {
  color: #0052ad;
  font-size: 24px;
  font-weight: 600;
  vertical-align: middle;
}
.additionalMethods ul.additionalMethodWrapper li.paymentMethod .paymentMethodType .methodDesc {
  padding-top: 10px;
  margin: 0;
}
.additionalMethods ul.additionalMethodWrapper li.paymentMethod .paymentMethodType .methodDesc a {
  color: #005bf0;
  text-decoration: underline;
}
.additionalMethods .note {
  color: #888888;
  font-style: italic;
}
.additionalMethods .note a {
  color: #005bf0;
  text-decoration: underline;
}
.proceedBtn {
  margin-top: 20px;
}
.proceedBtn .btns {
  width: 322px;
}
.proceedBtn .disabled,
input:disabled,
.proceedBtn input[disabled]:hover,
.mainPageBtn .depositBtn input[disabled]:hover {
  background: #aaaaaa;
  color: #ffffff;
}
.paymentMethod.addCardForm {
  padding-bottom: 35px;
}
.paymentMethod .form-group {
  position: relative;
}
.paymentMethod .form-group .helpText {
  position: absolute;
  right: 10px;
  top: 22%;
  color: #c0c0c0;
  font-size: 12px;
  font-style: italic;
}
.paymentMethod .addAmount {
  padding-right: 85px;
}
.paymentMethod .form-control {
  border-color: #e3e3e3;
}
.paymentMethod .form-control:focus {
  border-color: #66afe9;
}
.paymentMethod label {
  font-weight: normal;
}
.expiryDateWrap .col-xs-4 {
  padding: 0;
}
.expiryDateWrap .col-xs-2 {
  padding: 0;
  text-align: center;
  vertical-align: middle;
  line-height: 43px;
}
.expiryDateWrap input[type='text'].form-control {
  padding: 0 0 0 5px;
}
.paymentMethod .safetyWrap {
  border-top: 1px solid #e4e4e4;
  padding-top: 20px;
  line-height: 18px;
}
.paymentMethod .safetyWrap p {
  color: #000;
  margin-bottom: 25px;
}
.paymentMethod .safetyWrap .titleInclude {
  font-weight: 600;
  color: #333;
  margin-bottom: 5px;
}
.paymentMethod .safetyWrap ul {
  list-style-type: none;
  margin-bottom: 30px;
}
.paymentMethod .safetyWrap li {
  margin: 0 0 10px 15px;
  position: relative;
}
.paymentMethod .safetyWrap li::before {
  position: absolute;
  left: -10px;
  top: 5px;
  content: '';
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  padding-right: 5px;
  background: #005bf0;
}
.paymentMethod .btnBlue {
  width: 320px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
}
.paymentMethod input[disabled] {
  background: #e3e3e3;
}
.addCardForm .row {
  margin-left: -10px;
  margin-right: -10px;
}
.addCardForm .col-sm-6 {
  padding-left: 10px;
  padding-right: 10px;
}
.addCardForm .col-sm-6 .row {
  margin-left: -5px;
  margin-right: -5px;
}
.addCardForm .col-sm-6 .row .col-xs-6 {
  padding-left: 5px;
  padding-right: 5px;
}
.addCardForm .defaultSelect {
  height: 43px;
}
.addCardForm .amtAvailable {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 20px;
  margin-bottom: 15px;
  color: black;
}
.addCardForm .noteAddress {
  color: #888888;
  background: #f2f2f2;
  font-size: 13px;
  line-height: 15px;
  padding: 5px;
  margin-bottom: 15px;
}
.addCardForm .updateInfo {
  margin-bottom: 15px;
  color: #888888;
}
.addCardForm .updateInfo a {
  color: #005bf0;
  font-style: italic;
  text-decoration: underline;
}
.addCardForm .updateInfo a:hover {
  color: #000;
}
.addCardForm [class^='icon-'],
.addCardForm [class*='icon-'] {
  position: absolute;
}
.addCardForm .icon-calender {
  top: 40px;
  right: 10px;
  width: 15px;
  height: 14px;
  background-position: -58px -146px;
  pointer-events: none;
}
.cvvField form {
  position: relative;
}
.addCardForm .icon-cvvInfo {
  top: 9px;
  right: 7px;
  width: 15px;
  height: 14px;
  background-position: -58px -168px;
  position: absolute;
}
.addCardForm .icon-cvvInfo:hover .IconTooltip {
  display: block;
}
.addCardForm.payPalMethodWrap .updateInfo {
  margin-top: 15px;
}
.paymentSummary {
  background: #f5f9ff;
  border: 1px solid #cbdfff;
  padding: 30px 34px;
}
.paymentSummary ul {
  list-style-type: none;
  margin: 0;
}
.paymentSummary li {
  margin-top: 10px;
}
.paymentSummary li:first-child {
  margin-top: 0;
}
.paymentSummary .paymentInfo {
  text-align: right;
  color: #005bf0;
  font-size: 16px;
}
.paymentSummary .billingAddress {
  border-top: 1px solid #dbe9ff;
  margin-top: 15px;
  padding-top: 15px;
}
.paymentSummary .billingAddress h4 {
  font-weight: bold;
  color: #000;
  font-size: 14px;
  margin: 0 0 10px 0;
}
.payPalMethodWrap ul,
.payPalMethodWrap li {
  list-style-type: none;
}
.showMenu {
  display: block;
}
.menuBtn {
  cursor: pointer;
}
.addCardErrorMsg {
  display: inline-block;
  color: #ec1c24;
  font-size: 12px;
  margin: 5px;
}
input[type='text'].form-control {
  height: 43px;
  padding: 0px 10px;
  line-height: 43px;
}
.transactionResultWrap .mainContentWrap {
  padding: 0;
}
.transactionResult {
  text-align: center;
  padding: 80px 50px 40px 50px;
}
.successIconWrap {
  background: #66aa31;
  width: 82px;
  height: 82px;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  position: relative;
  margin-bottom: 26px;
}
.transactionResult .successIcon {
  background: url(../images/sprite.png) no-repeat -85px -172px;
  display: inline-block;
  width: 30px;
  height: 25px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.creditDebitCardWrap .transactionResult h2 {
  font-size: 30px;
  color: #333333;
  font-weight: normal;
}
.transactionResult p {
  font-size: 14px;
  margin: 15px 0 25px 0;
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
}
.transactionResult .balanceAmount {
  font-size: 18px;
  color: #000;
}
.transactionActions .btns {
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  margin: 0 5px;
  border: 1px solid #ccdefc;
  color: #005bef;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
}
.transactionActions .btns:hover,
.transactionActions .btns:active {
  background: #005bef;
  color: #fff;
}
.rightSideBar {
  background: #f4f6fa;
  min-height: 500px;
}
.relative {
  position: relative;
}

/* Error Message*/
.errMsg {
  color: #d7030b;
}
.errorRefresh {
  padding-top: 20px;
}
.errorRefresh span,
.errorRefresh i {
  float: left;
}
.errorRefresh span {
  margin-right: 10px;
}
.errorRefresh i {
  width: 18px;
  height: 18px;
  background-position: -55px -266px;
  cursor: pointer;
}
/* Deposit Method Title */
.depositMethodTitle {
  margin-top: 0;
}
/* Custom Pop up starts*/
.customPopupWrap,
.popUpbg {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 4;
}
.IconTooltip {
  display: none;
  background: #333333;
  border-radius: 3px;
  padding: 10px;
  color: #eeeeee;
  position: absolute;
  width: 165px;
  top: 24px;
  left: -25px;
  font-size: 12px;
  font-family: 'Arial', Helvetica Neue, Helvetica, sans-serif;
  text-transform: capitalize;
  text-align: left;
}
.IconTooltip:before {
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 8px solid #333;
  position: absolute;
  content: '';
  top: -7px;
  left: 22px;
}
.popUpbg {
  background: #000;
  opacity: 0.8;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.popUpCnt {
  background: #fff;
  padding: 20px;
  width: 500px;
  position: absolute;
  top: 100px;
  z-index: 2;
  margin-left: -250px;
  left: 50%;
  text-align: center;
}
.popUpCnt p {
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 20px;
}
.popUpCnt button {
  width: 100px;
}
.popUpCnt .icon-closePopup {
  background-position: -58px -187px;
  width: 15px;
  height: 15px;
  position: absolute;
  right: 10px;
  top: 10px;
}
/*Loader*/
.postloader {
  background: url(../images/loader.gif) no-repeat scroll center center #b7b7b7;
  height: 100%;
  left: 0;
  opacity: 0.9;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 500;
}
/*Loader End*/
/* Custom Pop up ends */
.depositMainWrap {
  padding-bottom: 20px;
}
.rushFeeWrap {
  color: #666;
  display: block;
}

.help-icon {
  background-image: url(../images/helpIconMain@2x.png);
  height: 18px;
  width: 18px;
  display: inline-block;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  vertical-align: middle;
}
.logout {
  color: #ec1c24;
}
.breadcrumb {
  margin-top: 65px;
}
.depositMainWrap .paymentMethod.deactive #cardPayment {
  height: 30px;
}
.depositMainWrap .paymentMethod.deactive .radioIcon {
  background-image: none;
  height: 30px;
  width: 30px;
  background: #ddd;
  border-radius: 100%;
  margin-left: 2px;
  pointer-events: none;
  cursor: default;
}
.classicDrfBetsLink {
  line-height: 50px;
}
.classicDrfBetsLink a {
  font-size: 16px;
  line-height: 18px;
  text-decoration: none;
  color: #333;
  font-family: Titillium Web, sans-serif;
}
.classicDrfBetsLink a:hover {
  color: #005bf0;
}
.hamburgerBtn .defaultDropList .betsMobLinkHide {
  display: block;
}
.hamburgerBtn .defaultDropList .betsDesktopLinkHide {
  display: none;
}
/* Main Navigation Menu: Start */
.menuNavigation li {
  list-style-type: none;
}
.menuNavigation li.noLinkOption a {
  padding: 0 10px;
  font-size: 16px;
  line-height: 50px;
  color: #333;
  text-decoration: none;
  display: block;
  font-family: Titillium Web, sans-serif;
}
.menuNavigation li.noLinkOption a:hover {
  color: #005bf0;
}
/* Main Navigation Menu: End */
.menuNavigation .defaultDropList li a {
  font-size: 14px;
}
.helpWrap .menuBtn .placeholder.helpPlaceholder {
  padding: 0;
}
/* Terms and Conditions: Start */
.termsAndConditions {
  color: #333;
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  margin-bottom: 30px;
}
.termsAndConditions h2,
.termsAndConditions h3 {
  font-weight: 700;
}
.termsAndConditions h4 {
  font-weight: 600;
  margin-bottom: 2px;
}
.termsAndConditions table {
  margin-bottom: 10px;
  margin-top: 10px;
}
.termsAndConditions table td {
  border: 1px solid #000;
  padding: 5px;
}
.termsAndConditions .partMainList,
.termsAndConditions .subCnt,
.termsAndConditions .miscellaneousList,
.termsAndConditions .drfFriendPgmList,
.termsAndConditions .partMainList.betsTermsAndCondList {
  padding-left: 20px;
  list-style-type: none;
}
.termsAndConditions .conditionalWagerList h4 {
  font-weight: 400;
}
.termsAndConditions .partMainList,
.termsAndConditions .drfFriendPgmList.betsTermsAndCondList,
.termsAndConditions .drfFriendPmgSubList,
.termsAndConditions .subCnt.betsTermsAndCondList {
  list-style-type: disc;
}
.termsAndConditions .conditionalWagerList,
.termsAndConditions .rewardList {
  list-style-type: none;
}
.termsAndConditions .ppList,
.termsAndConditions .drfFriendPmgSubList {
  padding-left: 20px;
}
.termsAndConditions .listItem {
  display: list-item;
  list-style-type: disc;
  margin-left: 20px;
}
.termsAndConditions .betsTermsAndCondList li {
  margin-bottom: 10px;
}
.termsAndConditions address p {
  margin-bottom: 5px;
}
.termsAndConditions .rewardList h4 {
  font-size: 15px;
}
.termsAndConditions .miscellaneousList,
.termsAndConditions .wageringServiceTerms .subCnt,
.termsAndConditions .wageringServiceTerms .partMainList.betsTermsAndCondList {
  padding-left: 0;
}
/* Terms and Conditions: End */

/* New Xpress Funding ACH account: Start */
.paymentMethod .paymentMethodType.xpressPaymentMethodType {
  float: left;
  margin-left: 10px;
  width: 60%;
}
/* New Xpress Funding ACH account: End */

/* Paypal info List: Start */
ul.paypalInfoList li {
  margin-left: 14px;
  position: relative;
  margin-bottom: 8px;
}
ul.paypalInfoList li:before {
  content: ' ';
  background: #66aa22;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  position: absolute;
  left: -14px;
  top: 4px;
}
.achAccount .paymentMethod.addCardForm {
  border-right: none;
}
.achAccount .userDetails {
  border-left: 1px solid #ddd;
}
.proceedBtn {
  margin-bottom: 10px;
}
.paymentMethod .defaultSelect {
  padding-right: 25px;
}
/* Paypal info List: End */
/* New UI for Credit and Debit card: Start */
.creditDebitCardWrap .mainContentWrap {
  border-radius: 4px;
  margin-bottom: 30px;
}
.creditDebitCardWrap .newCardBtn button {
  width: 100%;
  border: 1px solid #ccdefc;
  border-radius: 4px;
  background: #fff;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 18px;
  color: #005bef;
  height: 40px;
}
.creditDebitCardWrap .newCardBtn button:hover,
.creditDebitCardWrap .newCardBtn button:active {
  background: #005bef;
  color: #fff;
}
.creditDebitCardWrap .pageTitle {
  margin-left: -40px;
}
.creditDebitCardWrap h2 {
  margin: 0;
  padding-bottom: 20px;
  color: #000;
  font-size: 24px;
}
.creditDebitCardWrap label {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  line-height: 17px;
  color: #666;
}
.creditDebitCardWrap .cardInfoBoxCnt {
  margin-bottom: 10px;
}
.creditDebitCardWrap .cardInfoBoxCnt input {
  border: 1px solid #ccdefc;
  height: 40px;
}
.creditDebitCardWrap h2 i {
  color: #005bef;
  font-size: 24px;
  margin-right: 18px;
  cursor: pointer;
}
.creditDebitCardWrap .paymentMethod {
  padding: 20px 48px;
}
.creditDebitCardWrap .depositAmountBoxWrap .btns,
.creditDebitCardWrap .depositAmountBoxWrap .form-group {
  display: inline-block;
  width: 22%;
  margin-right: 4%;
}
.creditDebitCardWrap .depositAmountBoxWrap .form-group {
  margin-right: 0;
}
.creditDebitCardWrap .depositAmountBoxWrap .form-control.addAmount {
  height: 40px;
  line-height: 40px;
  padding: 5px;
  font-weight: 400;
  font-size: 15px;
  border: 1px solid #ccdefc;
}
.creditDebitCardWrap .depositAmountBoxWrap .form-control,
.creditDebitCardWrap .expiryDateFields input,
.creditDebitCardWrap .cvvWrap input {
  text-align: center;
  color: #005bef;
  font-weight: 700;
}
.creditDebitCardWrap .cardNumberInputWrap form {
  position: relative;
}
.creditDebitCardWrap .cardNumberInputWrap input {
  font-weight: 700;
  color: #000;
  padding-right: 50px;
}
.creditDebitCardWrap .cardNumberInputWrap .eCard {
  width: 33px;
  height: 23px;
  position: absolute;
  right: 9px;
  top: 9px;
}
.creditDebitCardWrap .cardNumberInputWrap .eCard.defaultCard {
  background: url('../images/eCard.png') 0 0 no-repeat;
  background-size: cover;
}
.creditDebitCardWrap .cvvWrap input::placeholder,
.creditDebitCardWrap .depositAmountBoxWrap .form-control::placeholder,
.creditDebitCardWrap .expiryDateFields input::placeholder,
.creditDebitCardWrap .cardNumberInputWrap input::placeholder,
.creditDebitCardWrap.achAccount input::placeholder,
.creditDebitCardWrap.achAccount .cardInfoBox .form-control::placeholder,
.creditDebitCardWrap.achAccount .cardInfoBox .form-control {
  color: #005bef;
  font-weight: 700;
}
.creditDebitCardWrap .depositAmountBoxWrap .btns {
  background: #fff;
  border: 1px solid #ccdefc;
  color: #005bef;
  border-radius: 4px;
  font-size: 15px;
  line-height: 28px;
  font-family: Arial, Helvetica, sans-serif;
}
.creditDebitCardWrap .depositAmountBoxWrap .btns.active,
.creditDebitCardWrap .depositAmountBoxWrap .form-group.active .form-control {
  background: #005bef;
  color: #fff;
  font-weight: 700;
}
.creditDebitCardWrap .depositAmountBoxWrap .form-group.active .form-control::placeholder {
  color: #fff;
  font-weight: 700;
}
.creditDebitCardWrap .depositAmountBoxWrap label {
  display: block;
}
.creditDebitCardWrap .cardInfoBoxCnt {
  border: 1px solid #ccdefc;
  border-radius: 4px;
  padding: 12px;
}
.creditDebitCardWrap .cardInfoBoxCnt .depositBtn .btns {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
}
.creditDebitCardWrap .cardInfoBoxCnt .selectCardWrap {
  margin-bottom: 10px;
}
.creditDebitCardWrap .expiryDateWrap label {
  margin-right: 15px;
  line-height: 45px;
}
.creditDebitCardWrap .expiryDateWrap .expiryDateFields {
  width: 120px;
}
.creditDebitCardWrap .expiryDateWrap .expiryDateFields input {
  border: none;
  width: 100%;
}
.creditDebitCardWrap .expiryDateWrap .expiryDateFields:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.creditDebitCardWrap .expiryDateWrap .expiryDateFields:first-child,
.creditDebitCardWrap .expiryDateWrap .expiryDateFields:last-child {
  border: 1px solid #ccdefc;
}
.creditDebitCardWrap .expiryDateWrap .expiryDateFields:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-left: none;
}
.creditDebitCardWrap .cvvWrap {
  position: relative;
}
.creditDebitCardWrap .cvvWrap .icon-cvvInfo {
  left: -20px;
  top: 14px;
}
.creditDebitCardWrap .cvvWrap input.cvvActualInput {
  border-radius: 3px;
  color: #005bef;
  font-weight: 700;
  text-align: center;
  height: 42px;
}
.creditDebitCardWrap .cvvWrap {
  width: 120px;
}
.creditDebitCardWrap .cvvWrap input.cvvActualInput::placeholder {
  font-weight: 400;
}
.creditDebitCardWrap .cardInfoBox .amtAvailable {
  border-bottom: none;
  margin-bottom: 15px;
  padding-bottom: 0;
  font-size: 13px;
  line-height: 15px;
  color: #000;
}
.creditDebitCardWrap .cardInfoBox .depositBtn,
.creditDebitCardWrap .supportSslWrap {
  margin-bottom: 15px;
}
.creditDebitCardWrap .supportSslWrap a:hover {
  text-decoration: none;
  color: #000;
}
.creditDebitCardWrap .cardInfoBox .depositFee {
  margin-bottom: 0;
}
.creditDebitCardWrap.transactionResultWrap {
  padding-top: 75px;
}
.payPalOption .paymentMethodType {
  margin-left: 35px;
}
.creditDebitCardWrap .mainContentWrap,
.creditDebitCardWrap .addCardForm .defaultSelect {
  border: 1px solid #ccdefc;
}
.creditDebitCardWrap .depositMainWrap,
.creditDebitCardWrap .cardMethod,
.creditDebitCardWrap .transactionWrap {
  border-right: 1px solid #ccdefc;
}
.creditDebitCardWrap .depositMainWrap .pageTitle {
  margin-left: 0;
  padding-top: 20px;
  padding-bottom: 12px;
}
.creditDebitCardWrap .paymentMethod {
  border-right: 0;
}
.creditDebitCardWrap .supportSslWrap {
  border-bottom: 1px solid #ccdefc;
  padding-bottom: 15px;
}
.creditDebitCardWrap .supportSslWrap i,
.creditDebitCardWrap .supportSslWrap div {
  display: inline-block;
  vertical-align: middle;
}
.creditDebitCardWrap .supportSslWrap div {
  width: 75%;
}
.creditDebitCardWrap .supportSslWrap div p {
  margin-bottom: 4px;
}
.creditDebitCardWrap .supportSslWrap i {
  background-repeat: no-repeat;
  color: #66aa22;
  font-size: 24px;
  text-align: center;
  margin-right: 16px;
}
.creditDebitCardWrap .customerSupportWrap i {
  background: url('../images/customer-support-bg.svg');
  width: 48px;
  height: 48px;
  line-height: 48px;
}
.creditDebitCardWrap .customerSupportWrap div p {
  font-size: 14px;
  color: #333;
}
.creditDebitCardWrap .customerSupportWrap div p.customerContact {
  font-weight: 700;
}
.creditDebitCardWrap .customerSupportWrap div p a {
  color: #005bef;
}
.creditDebitCardWrap .sslSecuredWrap i {
  background: url('../images/ssl-secured-bg.svg');
  width: 47px;
  height: 47px;
  line-height: 47px;
}
.creditDebitCardWrap .sectionInfo {
  padding-top: 16px;
}
.creditDebitCardWrap .sslSecuredWrap div p:first-child {
  font-size: 16px;
  color: #333;
  line-height: 18px;
  font-weight: 700;
}
.creditDebitCardWrap .sslSecuredWrap div p:last-child {
  font-size: 12px;
  line-height: 14px;
  color: #666;
}
.creditDebitCardWrap .needHelpWrap p {
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  color: #333;
}
.creditDebitCardWrap .needHelpWrap a:hover {
  text-decoration: none;
  color: #000;
}
.creditDebitCardWrap .needHelpWrap i,
.creditDebitCardWrap .creditDebitSelect .selectedCardWrap .cardArrow {
  background: url('../images/dropArrowDown@2x.png') 0 0 no-repeat;
  width: 13px;
  height: 7px;
  background-size: cover;
  display: block;
}
.creditDebitCardWrap .creditDebitSelect .selectedCardWrap span,
.creditDebitCardWrap .creditDebitSelect .selectCardList li {
  font-size: 15px;
  line-height: 18px;
  color: #000;
  font-weight: 700;
  margin-top: 3px;
  font-family: Arial, Helvetica, sans-serif;
}
.creditDebitCardWrap .selectedCardWrap .cardSelected,
.creditDebitCardWrap .creditDebitSelect .selectCardList li i {
  margin-right: 10px;
}
.creditDebitCardWrap .selectedCardWrap .cardSelected.master-card,
.creditDebitCardWrap .creditDebitSelect .selectCardList li i.master-card,
.creditDebitCardWrap .cardNumberInputWrap .eCard.master-card {
  background: url('../images/master-card.png') 0 0 no-repeat;
  height: 21px;
  width: 34px;
  background-size: cover;
}
.creditDebitCardWrap .selectedCardWrap .cardSelected.xpressBet,
.creditDebitCardWrap .creditDebitSelect .selectCardList li i.xpressBet,
.creditDebitCardWrap .bankTransferLogoText i {
  background: url('../images/bank-logo.png');
  height: 25px;
  width: 22px;
  background-size: cover;
}
.creditDebitCardWrap .selectedCardWrap .cardSelected.visa,
.creditDebitCardWrap .creditDebitSelect .selectCardList li i.visa,
.creditDebitCardWrap .cardNumberInputWrap .eCard.visa {
  background: url('../images/visa.png') 0 0 no-repeat;
  height: 24px;
  width: 34px;
  background-size: cover;
}
.creditDebitCardWrap .creditDebitSelect .selectedCardWrap .cardArrow {
  margin-top: 10px;
}
.creditDebitCardWrap .needHelpWrap div.arrowWrap {
  padding: 8px;
  display: none;
  cursor: pointer;
}
.creditDebitCardWrap .needHelpWrap.active div i,
.creditDebitCardWrap .creditDebitSelect.active .selectedCardWrap .cardArrow {
  background: url('../images/dropArrowUp@2x.png') 0 0 no-repeat;
  background-size: cover;
}
.creditDebitCardWrap .needHelpWrap ul {
  list-style-type: none;
}
.creditDebitCardWrap .needHelpWrap.active ul {
  display: block;
}
.creditDebitCardWrap .needHelpWrap ul a {
  color: #005bef;
  font-size: 14px;
  line-height: 30px;
}
.creditDebitCardWrap .mainContentWrap {
  padding: 0;
}
.availableBalWrap {
  background: #ccdefc;
  padding: 0 10px;
  text-align: center;
  font-size: 15px;
  line-height: 50px;
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  display: none;
}
.creditDebitCardWrap .paymentMethods .paymentMethodType {
  margin-left: 10px;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.creditDebitCardWrap.breadCrumbHide {
  padding-top: 65px;
}
.creditDebitCardWrap .creditDebitSelect .selectedCardWrap {
  height: 40px;
  border: 1px solid #ccdefc;
  border-radius: 4px;
  padding: 7px 10px;
  cursor: pointer;
}
.creditDebitCardWrap .creditDebitSelect {
  position: relative;
}
.creditDebitCardWrap .creditDebitSelect .selectCardList {
  background: #fff;
  position: absolute;
  width: 100%;
  list-style-type: none;
  z-index: 1;
  border: 1px solid #ccdefc;
  border-top: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 200px;
  overflow-y: scroll;
  display: none;
}
.creditDebitCardWrap .creditDebitSelect .selectCardList li {
  padding: 10px;
  cursor: pointer;
  border-top: 1px solid #ccdefc;
}
.creditDebitCardWrap .creditDebitSelect .selectCardList li:first-child {
  border-top: none;
}
.creditDebitCardWrap .creditDebitSelect.active .selectCardList {
  display: block;
}
.creditDebitCardWrap .creditDebitSelect .selectedCardWrap .selectCardPlaceholder,
.creditDebitCardWrap .creditDebitSelect .selectCardList .cardPlaceholder,
.creditDebitCardWrap .depositAmountBoxWrap .form-control::placeholder,
.creditDebitCardWrap .cardNumberInputWrap input::placeholder,
.creditDebitCardWrap .achAccountInfo input::placeholder,
.creditDebitCardWrap .cardInfoBoxCnt .form-control::placeholder,
.creditDebitCardWrap.achAccount .cardInfoBox .form-control::placeholder {
  font-weight: 400;
}
.creditDebitCardWrap .transactionMessage p:last-child {
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 14px;
}
.creditDebitCardWrap .transactionMessage p,
.creditDebitCardWrap .transactionMessage p.noBalance {
  font-size: 14px;
  line-height: 16px;
  color: #333;
  margin-top: 0;
  margin-bottom: 8px;
  font-family: Arial, Helvetica, sans-serif;
}
.creditDebitCardWrap .transactionMessage p.noBalance {
  margin-bottom: 14px;
}
.creditDebitCardWrap .transactionActions .btns {
  margin: 0;
}
.creditDebitCardWrap .paypalInfoWrap .btnBlue {
  width: 100%;
  margin-bottom: 15px;
  font-weight: 700;
}
.creditDebitCardWrap .paypalInfoWrap .depositFee,
.creditDebitCardWrap .amtAvailable {
  border-bottom: 0;
  padding-bottom: 0;
}
.creditDebitCardWrap .paypalInfoWrap .updateInfo,
.creditDebitCardWrap .achTerms p {
  color: #333;
  font-size: 13px;
  line-height: 18px;
  font-family: Arial, Helvetica, sans-serif;
}
.creditDebitCardWrap .paypalInfoWrap .updateInfo p {
  line-height: 24px;
  color: #000;
}
.creditDebitCardWrap .form-control {
  border: 1px solid #ccdefc;
}
.creditDebitCardWrap .noteAddress {
  background: #e0eed3;
  color: #666;
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif;
  margin: -20px 50px 10px;
  padding: 10px 15px;
}
.creditDebitCardWrap .noteAddress span {
  color: #000;
}
.creditDebitCardWrap .achTermLink {
  font-size: 14px;
  line-height: 30px;
  color: #005bef;
  cursor: pointer;
  margin-top: 15px;
  display: inline-block;
}
.creditDebitCardWrap.achAccount .btnGreen {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  height: 40px;
}
.creditDebitCardWrap .accountTypeWrap {
  margin-bottom: 15px;
}
.creditDebitCardWrap .accountTypeWrap .checkboxWrap {
  cursor: pointer;
}
.creditDebitCardWrap .accountTypeWrap label {
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
}
.creditDebitCardWrap .accountTypeWrap label .savingType {
  font-size: 15px;
  color: #666;
  font-weight: 700;
  padding-left: 8px;
}
.creditDebitCardWrap .accountTypeWrap label:last-child {
  margin-right: 0;
}
.creditDebitCardWrap .accountTypeWrap label span:first-child {
  color: #005bef;
  font-size: 20px;
}
.creditDebitCardWrap .accountTypeWrap input[type='radio'],
.creditDebitCardWrap .accountTypeWrap label span:first-child {
  width: 20px;
}
.creditDebitCardWrap .accountTypeWrap input[type='radio'] {
  opacity: 0;
}
.creditDebitCardWrap .cardInfoBox .newAccount {
  border: none;
  padding: 0;
}
.payPalOption.paymentMethod .withdrawPaypal {
  margin-left: 35px;
}
.creditDebitCardWrap.achAccount .achAccountInfo .form-control {
  height: 40px;
}
.creditDebitCardWrap .btnGreen {
  border: 1px solid #6a3;
}
input[disabled].btnGreen {
  border: none;
}
.creditDebitCardWrap .form-control {
  outline: none;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.creditDebitCardWrap .addCardErrorMsg {
  margin: 5px 0;
}
.creditDebitCardWrap .paymentMethods .paymentMethodType .methodName i.paypalMethod,
.withdrawPaypal.depositPayMethodBox .methodName i.paypalMethod {
  background: url('../images/paypal-main.png') 0 0 no-repeat;
  height: 29px;
  width: 112px;
  background-size: cover;
}
.creditDebitCardWrap .paymentMethods .depositPayMethodBox .methodName i.visaCard {
  background: url('../images/visa-card-mian.png') 0 0 no-repeat;
  height: 35px;
  width: 50px;
  background-size: cover;
  margin-right: 10px;
}
.creditDebitCardWrap .paymentMethods .depositPayMethodBox .methodName i.masterCard {
  background: url('../images/master-card-main.png') 0 0 no-repeat;
  height: 32px;
  width: 50px;
  background-size: cover;
}
.creditDebitCardWrap .paymentMethods .depositPayMethodBox .methodName i.visaCard,
.creditDebitCardWrap .paymentMethods .depositPayMethodBox .methodName i.masterCard {
  vertical-align: middle;
}
.creditDebitCardWrap .newCardBtn .addNewBtn {
  background: #fff;
  color: #005bef;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  border: 1px solid #005bef;
  font-family: Titillium Web, sans-serif;
}
.creditDebitCardWrap .bankTransferLogoText span,
.creditDebitCardWrap .bankTransferLogoText i {
  display: inline-block;
  vertical-align: middle;
}
.creditDebitCardWrap .bankTransferLogoText span {
  font-size: 18px;
  color: #333;
  line-height: 20px;
  font-weight: 700;
}
.creditDebitCardWrap .bankTransferLogoText i {
  width: 30px;
  height: 33px;
  margin: 0 5px 5px 0;
}
.creditDebitCardWrap .paymentMethods li.paymentMethod,
.creditDebitCardWrap .xpressPaymentMethodType .methodName {
  cursor: pointer;
}
.creditDebitCardWrap .xpressPaymentMethodType .methodName {
  margin-bottom: 0;
}
/* New UI for Credit and Debit card: End */
.achAccount .newLink {
  padding-top: 25px;
}
.achAccount .newLink .btns {
  background: transparent;
  color: #005bef;
}
.optionHide {
  display: none;
}
/* Entitlement Count Display: Start */
.entitlementWrap .ppStoreName {
  float: left;
  line-height: 31px;
}
.helpDropdown li.entitlementWrap a {
  overflow: hidden;
  padding: 7px 15px;
}
.entitlementWrap .entitlementCount {
  float: right;
  background: #ec1c24;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  border-radius: 100%;
  font-family: Arial, sans-serif;
  color: #fff;
  font-weight: 700;
}
.entitlementWrap span.fullCount,
.entitlementWrap .entitlementCount:hover span.twentyPlusCount {
  display: none;
}
.entitlementWrap .entitlementCount:hover {
  background: #005bef;
}
.entitlementWrap .entitlementCount:hover span.fullCount {
  display: block;
}

/* Entitlement Count Display: End */

.userEmailId div:first-child {
  padding-bottom: 15px;
}
.singleMenuWrap {
  padding: 16px 10px;
}
.singleMenuWrap a {
  font-size: 16px;
  line-height: 18px;
  color: #333;
  font-family: Titillium Web, sans-serif;
}
.singleMenuWrap a:hover,
.singleMenuWrap a:active {
  text-decoration: none;
}

/* Coachtip Graphic: Start */
.creditDebitCardWrap.achAccount .accountCoachtipWrap .accountTypeWrap,
.creditDebitCardWrap.achAccount .accountCoachtipWrap .coachGrapichWrap {
  display: inline-block;
  vertical-align: middle;
  width: 50%;
}
.creditDebitCardWrap.achAccount .accountCoachtipWrap .accountTypeWrap {
  width: 60%;
  margin-right: 2%;
}
.creditDebitCardWrap.achAccount .accountCoachtipWrap .coachGrapichWrap {
  width: 38%;
}
.creditDebitCardWrap.achAccount .accountCoachtipWrap .coachGrapichWrap img {
  display: block;
}
.explainer-one-symbol,
.explainer-two-symbol {
  display: inline-block;
  width: 11px;
  height: 11px;
  vertical-align: bottom;
}
.explainerText {
  font-size: 12px;
  line-height: 14px;
  color: #666;
  margin-top: 3px;
}
.explainer-one-symbol {
  background: url('../images/explainer-text-symbol-one.gif') 0 0 no-repeat;
  background-size: 100%;
}
.explainer-two-symbol {
  background: url('../images/explainer-text-symbol-two.gif') 0 0 no-repeat;
  background-size: 100%;
}
/* Coachtip Graphic: End */
/* Funding Iframe: Start */
.fundingIframeLoder {
  display: none;
}
.iframeClass .fundingIframeLoader {
  background-image: url(../images/loader.gif);
  height: 112px;
  width: 202px;
  display: block;
  position: absolute;
  margin-top: -56px;
  margin-left: -101px;
  top: 50%;
  left: 50%;
}
.iframeClass .creditDebitCardWrap.breadCrumbHide .iframeClass .paymentMethodWrap .pageTitle {
  padding-top: 15px;
}
.iframeClass#app-container,
.iframeClass .creditDebitCardWrap .sectionInfo {
  padding-bottom: 0;
}
.iframeClass .paymentMethodWrap .pageTitle {
  position: fixed;
  background: #fff;
  width: 100%;
  top: 0;
  z-index: 1;
  left: 0;
  -webkit-box-shadow: 0 5px 4px -6px #ededed;
  -moz-box-shadow: 0 5px 4px -6px #ededed;
  box-shadow: 0 5px 4px -6px #ededed;
  padding: 13px 15px;
}
.iframeClass .creditDebitCardWrap.paymentMethodWrap .depositMainWrap .pageTitle {
  padding: 15px 15px 16px;
}
.iframeClass .paymentMethodWrap .pageTitle h2 {
  padding-bottom: 0;
}
.iframeClass .paymentMethodWrap .availableBalWrap {
  margin-top: 36px;
}
.iframeClass .paymentMethodWrap .transactionWrap .transactionActions {
  display: none;
}
/* Funding Iframe: End */

/* Quick Deposit: Start */
.addQuickAmountWrap,
.quickDepMobileShow,
.depositMainWrap .depositAmountBoxWrap,
.addAmountBox .form-group.quickDepMobileShow,
.accTypeLabels .checkboxWrap.quickDepMobileShow,
.creditDebitCardWrap .accountTypeWrap .labelClass.quickDepMobileShow {
  display: none;
}
.expiryDateWrap .quickDepMobileHide,
.accTypeLabels .checkboxWrap.quickDepMobileHide,
.accTypeLabels {
  display: inline-block;
}
.paymentMethod .accountCoachtipWrap .form-group {
  margin-bottom: 20px;
}
.col-sm-9.cardMethod.payPalOptionWrap {
  padding-bottom: 135px;
}
.iframeClass .col-sm-9.cardMethod {
  padding-top: 215px;
}
.iframeClass .col-sm-9.cardMethod.depBoxInputErr {
  padding-top: 222px;
}
.iframeClass .col-sm-9.cardMethod.weeklyAmtAvail {
  padding-top: 235px;
}
.iframeClass .col-sm-9.cardMethod.weeklyAmtAvail.depBoxInputErr {
  padding-top: 248px;
}
.iframeClass .col-sm-9.cardMethod.payPalOptionWrap {
  padding-top: 205px;
}
.cardOptionWrap .paymentMethod .form-group {
  margin-bottom: 15px;
}
.mobilePageTitle.stickyHeadTitle {
  display: none;
}
.iframeClass .mobilePageTitle.stickyHeadTitle {
  display: block;
}
.iframeClass .creditDebitCardWrap .mobilePageTitle h4 {
  margin-top: -10px;
}
.iframeClass .depositMainWrap .mobilePageTitle h4 {
  margin-top: 0;
}
.iframeClass .depositMainWrap .stickyHeadWrap {
  top: 28px;
}
.iframeClass .mobilePageTitle.stickyTitle {
  top: 0;
}
.creditDebitCardWrap .paypalInfoWrap .btnBlue {
  background: #6a3;
}
.creditDebitCardWrap .paypalInfoWrap .btnBlue[disabled] {
  background: #e3e3e3;
}
.iframeClass .stickyHeadWrap {
  position: fixed;
}
.creditDebitCardWrap .depositAmountBoxWrap .form-control {
  color: #000;
}
/* Quick Deposit: End */
.withBalWrap {
  margin-bottom: 5px;
}
.withBalWrap label,
.withBalWrap span {
  display: inline-block;
}
.withBalWrap label {
  font-weight: 600;
  margin-right: 6px;
}
/* Footer: Start */
.mainContentWrap {
  padding-bottom: 30px;
}
.footerMenuCopyright .storeIcons .socialIcons li:first-child {
  margin-right: 8px;
}
.footerMenuCopyright .storeIcons .socialIcons li:first-child:hover svg g > path:nth-child(2),
.footerMenuCopyright .storeIcons .socialIcons li:last-child:hover svg > g > path:first-child {
  fill: #666;
}
.footerMenuCopyright .storeIcons .socialIcons li a {
  margin-bottom: 0;
  width: auto;
}
/* Footer: End */

.addAmountBox label {
  text-align: right;
}
/* Withdraw Mobile View: Start */
.withdrawWrapper .availableBalWrap {
  display: none;
}
.withdrawWrapper .mobileHide {
  display: block;
}
.withdrawWrapper .desktopHide {
  display: none;
}
.paymentMethods .payPalOption.paymentMethod .paymentRadioBtn {
  margin-right: 5px;
}
.withdrawMoney {
  font-family: 'Titillium Web', sans-serif;
}
.partnerRaceTracks a {
  font-size: 13px;
  line-height: 16px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 700;
  color: #06c;
}
.partnerRaceTracks {
  margin-bottom: 4px;
}
.partnerRaceTracks a span,
.partnerRaceTracks a i {
  display: inline-block;
  vertical-align: bottom;
}
.partnerRaceTracks i {
  height: 20px;
}
.partnerRaceTracks .iconSvg-PopOut g > g {
  stroke: #06c;
}
.mainWithdrawWrap .balShow {
  display: none;
}
.paypalWithdrawWrap .paypalInfoList {
  display: block;
}
/* Withdraw Mobile View: End */

/* Plaid Funding : Start */
.errorView {
  padding: 65px 24px 0 24px;
}
.errorView .errorIcon {
  background: url('../images/error.svg');
  display: block;
  width: 48px;
  height: 48px;
  margin: 0 auto 18px auto;
}
.errorView .amount {
  font-size: 32px;
  line-height: 34px;
  color: #000;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 700;
  margin-bottom: 16px;
}
.errorView .errorType {
  font-size: 21px;
  line-height: 28px;
  color: #000;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 700;
  margin-bottom: 12px;
}
.errorView .contactInfo {
  font-size: 15px;
  line-height: 24px;
  color: #000;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
  margin-bottom: 16px;
}
.errorView .contactInfo a {
  color: #005bef;
  text-decoration: underline;
  margin: 0 4px;
}
.errorView .btns {
  width: 100%;
  font-size: 15px;
  line-height: 18px;
  padding: 15px 0;
  background: #693;
  margin-bottom: 16px;
}
.errorView .transactionResult {
  padding-bottom: 16px;
}
.plaidFunding {
  margin-top: 30px;
}
.plaidFunding h4 {
  text-align: center;
  padding: 0 65px;
  font-size: 21px;
  line-height: 28px;
  color: #000;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 700;
}
.plaidFunding .paypalInfoList li,
.plaidFunding .buttonText {
  margin-left: 0;
  font-size: 13px;
  line-height: 20px;
  color: #000;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
  margin-bottom: 12px;
}
.plaidFunding .paypalInfoList li span {
  font-weight: 700;
}
.plaidFunding .paypalInfoList li a {
  text-decoration: underline;
  margin: 0 4px;
  color: #005bef;
}
.plaidFunding .buttonText {
  margin-bottom: 8px;
}
.plaidFunding .paypalInfoList li::before {
  content: '';
  display: none;
}
.plaidFunding .quickDepMobileShow.helpContentWrap,
.errorView .quickDepMobileShow.helpContentWrap {
  padding-left: 0;
  padding-right: 0;
}
.plaidFunding .paypalInfoList .plaidHeading {
  font-size: 15px;
  line-height: 24px;
  color: #000;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 700;
  margin: 0;
}
.plaidFunding .paypalInfoWrap {
  margin-top: 31px;
}
/* Plaid Funding: End */

/* Media Query */

@media only screen and (min-width: 1200px) {
  .container {
    width: 1200px;
  }
  .mobileMenuDropdown {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .footerSecondary {
    width: calc(100% - 235px);
  }
}
@media only screen and (max-width: 1199px) {
  /* Footer: Start*/
  .mainContentWrap {
    margin-bottom: 60px;
  }
  .creditDebitCardWrap .mainContentWrap {
    margin-bottom: 90px;
  }
  /* Footer: End */
  .optionHide {
    display: block;
  }
  .userNameAccountIdWrap {
    width: 87%;
  }
  .userNameAccountIdWrap .userAccountId {
    margin-left: 47px;
    display: block;
  }
  /* Entitlement Count Display: Start */
  .entitlementWrap .ppStoreName {
    line-height: 30px;
  }
  .mobileMenubar .mobileMenuDropdown ul .profileLinkWrap li.entitlementWrap a {
    overflow: hidden;
  }
  .mobileMenubar .mobileMenuDropdown .headerlinkList .helpMenu {
    padding: 10px 9px;
  }
  .hedeMenuInfoWrap .helpDrop {
    line-height: 26px;
  }
  /* Entitlement Count Display: End */

  /* New UI for Credit and Debit Card: Start */
  .creditDebitCardWrap .supportSslWrap div {
    width: 68%;
  }
  .availableBalWrap {
    display: block;
  }
  .withdrawWrapper .availableBalWrap {
    display: none;
  }
  /* New UI for Credit Card and Debit Card: End */
  /* Header: Start */
  .mobileMenubar .userBalanceWrap.depositHide > div:first-child {
    text-align: left;
    padding-left: 25px;
  }
  .mobileMenuDropdown .headerlinkList .betsDesktopLinkHide {
    display: block;
  }
  .menuNavigation,
  .mobileMenuDropdown .headerlinkList .betsMobLinkHide {
    display: none;
  }
  .mobileMenubar .mobileMenuDropdown ul li.logoutLink {
    border-top: 0;
  }
  .mobileMenubar .mobileMenuDropdown ul li.freePpClass,
  .mobileMenubar .mobileMenuDropdown ul li:last-child {
    border-bottom: 0;
  }
  .mobileMenubar .mobileMenuDropdown .headerlinkList > li.helpMenu,
  .mobileMenubar .loggedUser {
    margin: 0;
  }
  .mobileMenubar .mobileMenuDropdown .headerlinkList > li,
  .mobileMenubar .mobileMenuDropdown ul li {
    padding: 9px;
  }
  .mobileMenubar .mobileMenuDropdown ul li.commonMenuLinks {
    padding: 13px 9px;
  }
  .mobileMenubar .mobileMenuDropdown ul li.profileDropArrow.active li {
    border-top: 1px solid #e1e1e3;
  }
  .mainLogo img {
    height: 48px;
    width: 145px;
  }
  .mobileMenubar .mobileMenuDropdown .headerlinkList > li .helpDrop:before,
  .mobileMenubar .mobileMenuDropdown .headerlinkList > li .helpDrop:after {
    content: '';
    display: none;
  }
  .mobHide,
  .hamburgerBtnBox.desktop {
    display: none !important;
  }
  .mainLogo a svg {
    width: 174px;
  }
  .mainLogo .mobileHide {
    display: none;
  }
  .mainLogo .mobileShow {
    display: block;
  }
  .mainLogo {
    height: 48px;
  }
  .hamburgerBtnBox.mobileHamburgerBtn {
    display: block;
  }
  .activeMenu:before {
    position: absolute;
    content: '';
    top: 45px;
    left: 0;
    width: 100%;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
  }
  .banner,
  .navMenuWrap,
  .headerLogin,
  .headerRegisterBtn,
  .helpWrap,
  .depositWrap,
  .memberDropdown {
    display: none;
  }
  .header {
    background: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 12;
    height: 48px;
  }
  .header .headerWrapInner {
    width: 100%;
  }
  .header .hamburgerBtnBox {
    float: right;
    z-index: 999;
    height: 48px;
  }
  .header .hamburgerBtnBox .hamburgerBtn {
    padding: 24px 0 23px;
    border-left: 1px solid #ddd;
  }
  .headerRegisterBtn,
  .headerMobLoginBtn {
    vertical-align: middle;
    margin: 9px 15px 0 0;
    display: inline-block;
    color: #005bf0;
    font-size: 12px;
    font-weight: 600;
  }
  .headerRegisterBtn {
    background: #fff;
    padding: 5px 11px;
    width: auto;
    border: 1px solid #005bf0;
  }
  .header .mainLogo {
    margin: 0;
  }
  .headBalance {
    display: none;
  }
  .closeIconWrap {
    padding: 15px 0;
  }
  .closeIcon {
    background-image: url('../images/close@2x.png');
    width: 14px;
    height: 14px;
    display: block;
    margin: auto;
    background-size: 14px auto;
  }
  .header .profileLinkWrap li {
    margin-left: 16px;
    padding: 15px 8px;
    margin-right: 16px;
  }
  .header .profileLinkWrap li a {
    font-family: 'Titillium Web', sans-serif;
  }
  .mobileMenubar .mobileMenuDropdown .profileLinkWrap li.logoutLink,
  .mobileMenubar .mobileMenuDropdown .headerlinkList > li .mobilehHelpList li:last-child {
    border-bottom: none;
  }
  .mobileMenubar .mobileMenuDropdown .headerlinkList > li .mobilehHelpList {
    margin: 0;
    padding-top: 13px;
  }
  .mobileMenubar .mobileMenuDropdown .headerlinkList > li .mobilehHelpList li {
    background: #eceff5;
    padding-left: 0;
    border-top: 1px solid #e1e1e3;
  }
  .mobileMenubar .mobileMenuDropdown .headerlinkList > li.helpMenu.active {
    padding-bottom: 0;
    background: #eceff5;
  }
  .mobileMenubar .mobileMenuDropdown .headerlinkList > li.helpMenu.active .helpDrop {
    color: #005bf0;
  }
  .mobileMenubar .mobileMenuDropdown .headerlinkList > li.helpMenu {
    padding-left: 20px;
    padding-right: 20px;
    background: #f5f5f5;
  }
  .mobileMenubar .mobileMenuDropdown .headerlinkList > li.helpMenu.active {
    border-bottom: 1px solid #ddd;
  }
  .mobileMenubar .mobileMenuDropdown .headerlinkList > li.commonMenuLinks {
    padding-left: 20px;
  }
  .mobileMenubar .mobileMenuDropdown .headerlinkList > li.commonMenuLinks:nth-child(6),
  .mobileMenubar .mobileMenuDropdown .headerlinkList > li.commonMenuLinks:nth-child(7),
  .mobileMenubar .mobileMenuDropdown .headerlinkList > li.commonMenuLinks:nth-child(10) {
    display: none;
  }
  .profileDropArrow .mobileDropFundingUser {
    padding: 17px 8px 10px;
  }
  .profileDropArrow .dropArrow-icon,
  .helpMenu .helpDropArrow-icon {
    width: 14px;
    height: 8px;
    display: block;
    background: url('../images/dropArrowDown@2x.png') no-repeat;
    background-size: 100% 100%;
  }
  .profileDropArrow.active .dropArrow-icon,
  .helpMenu.active .helpDropArrow-icon {
    background: url('../images/dropArrowUp@2x.png') no-repeat;
    background-size: 100% 100%;
  }
  .helpMenu .mobileDropFundingHelp {
    padding: 10px 4px;
  }
  .helpMenu .helpDrop {
    width: 82%;
  }
  .mobileMenubar .mobileMenuDropdown ul li.profileDropArrow.active,
  .mobileMenubar .mobileMenuDropdown ul li.profileDropArrow.active li {
    background: #eceff5;
  }
  .mobileLogoSvgWrap svg {
    margin: 10px auto 0 auto;
    display: block;
  }
  /*header ends*/

  .navMenuWrap {
    margin-right: 5px;
  }
  .headerLogin .form-group,
  .headerLogin .headerSelect {
    width: 155px;
  }
  .headerRegisterBtn,
  .headerLoginBtn {
    width: 81px;
    font-size: 14px;
  }
  .helpWrap > a {
    padding: 22px 10px;
  }
  /*header ends*/

  .rightSidebarWrap {
    width: 230px;
    margin-left: -2px;
    border-left: 0;
  }
  .mobileMenubar .mobileMenuDropdown ul.profileLinkWrap li {
    padding-top: 13px;
    padding-bottom: 13px;
  }
  .availableBalWrap {
    margin-bottom: 15px;
  }
  .availableBalWrap p {
    margin-bottom: 0;
  }
  .availableBalWrap .balLowText {
    display: none;
  }
  .availableBalWrap.balLowWarning {
    background: #fffff0;
    color: #ec1c24;
    border: 1px solid #ccc;
  }
  .availableBalWrap.balLowWarning .balLowText {
    display: inline;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 991px) {
  .creditDebitCardWrap .supportSslWrap a:hover,
  .creditDebitCardWrap .needHelpWrap a:hover {
    color: #06c;
  }
  /* Footer: Start */
  .mainContentWrap,
  .creditDebitCardWrap .mainContentWrap {
    margin-bottom: 80px;
  }
  .withdrawWrapper .mainContentWrap {
    margin-bottom: 50px;
  }
  /* Footer: End */
  .mobileMenuDropdown .popupContent .headerlinkList > .commonMenuLinks:last-child {
    display: none;
  }
  /* New UI for Credit and Debit Card: Start */
  .creditDebitCardWrap .supportSslWrap i {
    margin-right: 5px;
  }
  .creditDebitCardWrap .supportSslWrap div {
    width: 65%;
  }
  .creditDebitCardWrap .sslSecuredWrap div p:first-child {
    font-size: 14px;
  }
  .creditDebitCardWrap .expiryDateWrap .expiryDateFields,
  .creditDebitCardWrap .cvvWrap {
    width: 78px;
  }
  .creditDebitCardWrap .cvvWrap input {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }
  /* New UI for Credit Card and Debit Card: End */

  .carryOverTitle,
  .btnFreePP,
  .wagerPad,
  .tabHide {
    display: none;
  }
  /*header starts*/

  .navMenuWrap {
    width: 40px;
  }
  .navMenuWrap .navMenuBtn span:before,
  .navMenuWrap .navMenuBtn span:after,
  .navMenuWrap .navMenuBtn span {
    width: 18px;
  }
  .helpWrap > a {
    font-size: 13px;
    padding: 22px 6px;
  }
  .headerLogin {
    padding: 10px 5px 0 5px;
    margin-right: 0;
  }
  .headerLogin .form-group,
  .headerLogin .headerSelect {
    width: 115px;
    font-size: 12px;
    margin-right: 0;
  }
  .headerLogin .form-group {
    margin-right: 10px;
  }
  .headerLogin > .form-group input {
    font-size: 12px;
    padding-left: 6px;
  }
  .headerRegisterBtn,
  .headerLoginBtn {
    font-size: 13px;
    width: 60px;
  }
  .headerlinkList .helpDrop {
    float: left;
    width: 90%;
  }
  .headerlinkList .helpDrop:after,
  .headerlinkList .helpDrop:before {
    border: none !important;
    margin: 0 !important;
  }
  .headerlinkList .icon-helpDropArrow {
    float: left;
    width: 13px;
    display: inline-block;
    height: 13px;
    background-position: -22px -203px;
  }
  .headerlinkList li.active .icon-helpDropArrow,
  .userLinks li:first-child.active .loginName .icon-DropArrow {
    background-position: -22px -215px;
  }
  .mobilehHelpList {
    clear: both;
    padding-top: 10px;
  }
  .mobileLogoutWrap {
    padding: 0 15px;
  }
  header .classicDrfBetsLink,
  header .headBalance,
  header .depositWrap,
  header .memberDropdown > .menuBtn {
    padding-left: 6px;
    padding-right: 6px; /*horizontal spance*/
  }
  /*header ends*/
  /*right sidebar starts*/

  .rightSidebarWrap {
    display: block;
    float: none;
    border: 0;
    width: 100%;
    clear: both;
  }
  .redSectionTitle {
    margin: 12px 0 10px;
    padding: 0;
    font-size: 22px;
  }

  /*race details starts*/

  /*exacta box starts*/

  .arrow.arrowLeft:focus,
  .arrow.arrowLeft:active,
  .arrow.arrowLeft,
  .arrow.arrowRight,
  .arrow.arrowRight:focus,
  .arrow.arrowRight:active,
  .raceListWrapper {
    background: #fff;
    border: 0;
  }
  .reactTabHide {
    display: none;
  }
  .dashboardContent .mainContentWrap {
    border-top: 0;
  }
  .paymentMethods .cardMethod.active .depositPayMethodBox {
    width: 60%;
  }
  .xpressPaymentMethodType {
    width: 55%;
  }
  .creditDebitCardWrap .customerSupportWrap div p a {
    font-size: 13px;
  }
}
@media only screen and (max-width: 767px) {
  /* Plaid Funding: Start */
  .plaidFunding .stickyBottomWrap,
  .errorView .stickyBottomWrap {
    padding-left: 15px;
    padding-right: 15px;
  }
  .plaidFunding .paypalInfoWrap {
    padding-bottom: 60px;
  }
  .errorView .quickDepMobileShow .helpContentWrap {
    display: flex;
  }
  .errorView {
    padding-top: 180px;
  }
  .errorView .contactInfo {
    margin-bottom: 0;
    padding-bottom: 90px;
  }
  .errorView .contactInfo a,
  .plaidFunding .paypalInfoList li a {
    color: #06c;
  }
  .errorView .btns {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    padding: 14px 0;
  }
  /* Plaid Funding: End */
  /* Footer: Start */
  .withdrawWrapper .mainContentWrap {
    margin-bottom: 0;
  }
  .paymentMethodWrap.withdrawWrapper {
    padding-bottom: 30px;
  }
  /* Footer: End */

  .depositMainWeeklyAmt p {
    margin-bottom: 0;
  }
  .iframeClass .creditDebitCardWrap.breadCrumbHide {
    padding-top: 15px;
  }
  .achAccount .pageTitle,
  .achAccount .viewTitle {
    float: none !important;
  }
  .achAccount .userDetails {
    border-left: none;
  }
  .proceedBtn {
    margin-bottom: 40px;
  }
  .paymentMethod.addCardForm {
    padding-bottom: 40px;
  }
  .paymentMethod.addCardForm .updateInfo {
    margin-top: 10px;
  }

  /* New UI for Credit and Debit Card: Start */
  .creditDebitCardWrap .mainContentWrap {
    border: none;
    margin-bottom: 0;
  }
  .creditDebitCardWrap .cardMethod .pageTitle,
  .creditDebitCardWrap .cardMethod .cardInfoBox,
  .creditDebitCardWrap .paypalInfoWrap,
  .creditDebitCardWrap .accountTypeWrap,
  .creditDebitCardWrap .achAccountInfo {
    padding-left: 15px;
    padding-right: 15px;
  }
  .creditDebitCardWrap.paymentMethodWrap {
    background: #fff;
  }
  .creditDebitCardWrap .transactionWrap {
    padding: 0 15px 0 15px;
  }
  .creditDebitCardWrap .transactionResult,
  .creditDebitCardWrap .accountTypeWrap,
  .creditDebitCardWrap .achAccountInfo {
    padding: 0;
  }
  .creditDebitCardWrap .sectionInfo {
    border-left: none;
    margin-left: 0;
    padding: 0;
  }
  .creditDebitCardWrap .cardInfoBox {
    margin-bottom: 25px;
  }
  .creditDebitCardWrap .depositMainWrap,
  .creditDebitCardWrap .cardMethod,
  .creditDebitCardWrap .transactionWrap {
    border-right: none;
  }
  .creditDebitCardWrap .transactionWrap .transactionActions .btns {
    width: 100%;
    margin: 0;
  }
  .creditDebitCardWrap .transactionResult h2 {
    font-size: 28px;
  }
  .creditDebitCardWrap .paymentMethod {
    padding: 0;
  }
  .creditDebitCardWrap .supportSslWrap:first-child {
    padding-top: 15px;
    border-top: 1px solid #ccdefc;
  }
  .creditDebitCardWrap .supportSslWrap,
  .creditDebitCardWrap .needHelpWrap {
    padding-left: 15px;
    padding-right: 15px;
  }
  .creditDebitCardWrap .supportSslWrap div {
    width: 75%;
  }
  .creditDebitCardWrap .supportSslWrap i {
    margin-right: 16px;
  }
  .creditDebitCardWrap .expiryDateWrap label {
    margin-right: 4px;
  }
  .creditDebitCardWrap .expiryDateWrap .expiryDateFields {
    width: 62px;
  }
  .creditDebitCardWrap .cvvWrap {
    width: 56px;
  }
  .creditDebitCardWrap .cvvWrap input {
    width: 100%;
  }
  .creditDebitCardWrap .newCardBtn {
    margin-top: 20px;
  }
  .creditDebitCardWrap .depositAmountBoxWrap .btns,
  .creditDebitCardWrap .depositAmountBoxWrap .form-group {
    width: 23%;
    margin-right: 2%;
  }
  .creditDebitCardWrap .depositAmountBoxWrap .btns {
    font-size: 15px;
  }
  .creditDebitCardWrap .expiryDateWrap input[type='text'].form-control {
    padding: 0;
  }
  .creditDebitCardWrap .sslSecuredWrap div p:first-child {
    font-size: 16px;
  }
  .creditDebitCardWrap .depositAmountBoxWrap .form-group {
    margin-right: 0;
    width: 25%;
  }
  .creditDebitCardWrap .needHelpWrap {
    background: #f8f9fc;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
  }
  .creditDebitCardWrap .needHelpWrap ul,
  .creditDebitCardWrap .sslSecuredWrap.supportSslWrap {
    margin-bottom: 0;
  }
  .creditDebitCardWrap .needHelpWrap div.arrowWrap {
    display: block;
  }
  .creditDebitCardWrap h2 {
    padding-bottom: 10px;
  }
  .creditDebitCardWrap h2 i {
    margin-right: 30px;
  }
  .creditDebitCardWrap .cardInfoBoxCnt .amtAvailable {
    padding-top: 0;
  }
  .creditDebitCardWrap .transactionActions {
    margin-bottom: 20px;
  }
  .creditDebitCardWrap .paymentMethod.addCardForm {
    margin-bottom: 0;
  }
  .creditDebitCardWrap .needHelpWrap ul {
    display: none;
  }
  .creditDebitCardWrap .needHelpWrap ul a:hover {
    text-decoration: none;
  }
  .creditDebitCardWrap .cardMethod {
    padding-left: 0;
    padding-right: 0;
  }
  .creditDebitCardWrap .depositMainWrap {
    padding-right: 0;
    padding-left: 0;
  }
  .creditDebitCardWrap .depositMainWrap .paymentMethodContent {
    padding-left: 15px;
    padding-right: 15px;
  }
  .creditDebitCardWrap .depositMainWrap .pageTitle {
    padding: 0 15px;
  }
  .creditDebitCardWrap .depositMainWrap .pageTitle .ttFont {
    padding-bottom: 0;
  }
  .creditDebitCardWrap .noteAddress {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 20px;
    font-family: 'Titillium Web', sans-serif;
  }
  .creditDebitCardWrap .payPalMethodWrap .paypalInfoWrap .depositFee {
    padding-top: 0;
  }
  .creditDebitCardWrap .cvvWrap .cvvDiscInput {
    width: 100%;
  }
  /* New UI for Credit Card and Debit Card: End */

  .header {
    height: 49px;
  }
  #app-container {
    padding-bottom: 0; /*footer height*/
  }
  .classicDrfBetsLink {
    display: none;
  }

  .notFoundWrap {
    width: 100%;
    position: static;
    margin: 50px auto;
    padding: 10px;
    max-width: 400px;
  }
  .notificationsWrap {
    width: 52px;
  }
  .notificationsWrap > a {
    padding: 12px 0 8px;
  }
  .notificationsWrap .notifyNumber {
    font-size: 9px;
    right: 10px;
  }
  .notificationsWrap .notifyDropdown {
    right: 0;
  }

  .rightSidebarWrap {
    width: auto;
    float: none;
  }
  /*rightsidebar ends*/

  /*footer starts*/

  .customSelect .optionsList .mtpTime {
    display: none;
  }
  .customSelect .optionsList .trackName {
    margin: 0;
  }
  /*footer ends*/
  .headBalance {
    display: none;
  }
  .proceedBtn .btns {
    width: 100%;
  }
  .paymentMethods .cardMethod.active .depositPayMethodBox {
    width: 90%;
  }
  .xpressPaymentMethodType {
    margin-bottom: 10px;
    float: none;
    width: 80%;
  }
  /* Terms and Condiitons: Start */
  .termsAndConditions {
    border: none;
  }
  .termsAndConditions .partMainList,
  .termsAndConditions .drfFriendPgmList,
  .termsAndConditions .subCnt.betsTermsAndCondList {
    padding-left: 15px;
  }
  .termsAndConditions .drfFriendPmgSubList {
    padding-left: 16px;
  }
  .termsAndConditions .subCnt,
  .termsAndConditions .partMainList.betsTermsAndCondList,
  .termsAndConditions .miscellaneousList {
    padding-left: 0;
  }
  .termsAndConditions .partMainList p,
  .termsAndConditions .partMainList.betsTermsAndCondList {
    margin-left: -15px;
  }
  .termsAndConditions h2 {
    font-size: 24px;
  }
  .termsAndConditions h3 {
    font-size: 18px;
    line-height: 26px;
  }
  .termsAndConditions h4 {
    font-size: 16px;
    line-height: 22px;
  }
  /* Terms and Conditions: End */
  .creditDebitCardWrap.achAccount .accountCoachtipWrap .accountTypeWrap,
  .creditDebitCardWrap.achAccount .accountCoachtipWrap .coachGrapichWrap {
    width: 100%;
    margin-right: 0;
  }
  .creditDebitCardWrap.achAccount .accountCoachtipWrap .accountTypeWrap {
    margin-bottom: 0;
  }
  .creditDebitCardWrap.achAccount .accountCoachtipWrap .coachGrapichWrap {
    margin-bottom: 15px;
    display: none;
  }
  .creditDebitCardWrap.achAccount .accountCoachtipWrap .coachGrapichWrap img {
    width: 290px;
    height: 166px;
    margin: 0 auto;
  }
  /* Quick Deposit View: Start */
  .addAmountBox .form-group.quickDepMobileShow {
    display: block;
  }
  .sectionInfo .supportSslWrap,
  .sectionInfo .needHelpWrap {
    display: none;
  }
  .creditDebitCardWrap .depositMainWrap {
    padding: 116px 15px 150px 15px;
    font-family: 'Titillium Web';
  }
  .creditDebitCardWrap .depositMainWrap .availableBalWrap {
    margin-left: 15px;
    margin-right: 15px;
  }
  .availableBalWrap {
    background: #f4f6fa;
    border: 1px solid #cccccc;
    color: #000;
    padding: 12px;
    font-size: 13px;
    font-family: 'Titillium Web';
    line-height: 16px;
    font-weight: 400;
    border-radius: 4px;
    text-align: left;
    margin-bottom: 16px;
    margin-top: 6px;
  }
  .depositAmountBoxWrap {
    display: block;
    padding-top: 16px;
    border-top: 1px solid #ccc;
    margin-left: 15px;
    margin-right: 15px;
  }
  .depositAmountBoxWrap .addAmountBox {
    border: 1px solid #ccc;
    padding: 12px 10px 0 12px;
    position: relative;
  }
  .depositAmountBoxWrap .addAmountBox .form-group {
    width: 40%;
    float: left;
    position: relative;
  }
  .creditDebitCardWrap .enterDepositAmount .addCardErrorMsg {
    position: absolute;
    top: 16px;
  }
  .depositAmountBoxWrap .addAmountBox .form-group,
  .depositAmountBoxWrap .addAmountBox ul,
  .depositAmountBoxWrap .addAmountBox label {
    margin-bottom: 0;
  }
  .creditDebitCardWrap .depositAmountBoxWrap .enterDepositAmount .form-control.addAmount {
    border: none;
    color: #000;
    font-size: 15px;
    line-height: 17px;
    width: 100%;
    margin-top: 8px;
    text-align: left;
    padding: 0 5px 0 0;
    font-family: 'Titillium Web', sans-serif;
    height: 24px;
  }
  .creditDebitCardWrap .depositAmountBoxWrap .form-group.active .form-control {
    background: #fff;
    color: #000;
    font-weight: 400;
  }
  .creditDebitCardWrap .depositAmountBoxWrap .enterDepositAmount .form-control.addAmount::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #000;
    opacity: 1; /* Firefox */
    font-weight: 400;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #000;
    font-weight: 400;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #000;
    font-weight: 400;
  }
  .depositAmountBoxWrap .addAmountBox label {
    font-size: 13px;
    line-height: 16px;
    color: #666;
    font-weight: 400;
    top: 8px;
    font-family: 'Titillium Web';
    position: absolute;
  }
  .depositAmountBoxWrap .depFundingOptions {
    list-style-type: none;
    float: right;
    display: flex;
    align-items: flex-end;
  }
  .depositAmountBoxWrap .enterDepositAmount {
    margin-top: 12px;
    height: 24px;
  }
  .depositAmountBoxWrap .addAmountBox .depFundingOptions button {
    float: left;
    border: 1px solid #0066cc;
    border-radius: 4px;
    padding: 0 5px;
    color: #0066cc;
    font-weight: 700;
    font-size: 13px;
    width: 46px;
    text-align: center;
    line-height: 30px;
    margin-right: 10px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  }
  .depositAmountBoxWrap .addAmountBox .depFundingOptions button:last-child {
    margin-right: 0;
  }
  .depositAmountBoxWrap p {
    font-size: 13px;
    line-height: 20px;
    font-family: 'Titillium Web', sans-serif;
  }
  .addAmountBox {
    margin-bottom: 8px;
  }
  .creditDebitCardWrap .depositMainWrap .paymentMethodContent {
    padding-left: 0;
    padding-right: 0;
  }
  .paymentMethodContent .paymentMethods li.paymentMethod,
  .withdrawWrapper .paymentMethods li.paymentMethod {
    box-shadow: none;
    border: 1px solid #ccc;
    padding: 8px 10px 8px 14px;
    background: #fff;
    position: relative;
    padding-left: 50px;
    margin-bottom: 8px;
  }
  .paymentMethodContent .paymentMethodType {
    font-family: 'Titillium Web';
    width: 100%;
    margin-left: 0;
  }
  .quickDepMobileShow {
    display: block;
  }
  .accTypeLabels .checkboxWrap.quickDepMobileShow {
    display: inline-block;
  }
  .paymentMethodContent .paymentMethodType,
  .accTypeLabels .checkboxWrap.quickDepMobileHide {
    display: none;
  }
  .paymentMethodContent .quickDepMobileShow i {
    position: absolute;
    top: 14px;
    left: 14px;
  }
  .paymentMethodContent .quickDepMobileShow h3,
  .withdrawWrapper .withdrawMainLabel {
    font-size: 15px;
    line-height: 24px;
    font-weight: 700;
    margin: 0;
    color: #0066cc;
  }
  .paymentMethodContent .quickDepMobileShow p {
    font-size: 13px;
    line-height: 16px;
    color: #666;
    margin-bottom: 0;
  }
  .quickDepMobileShow.banckAccountQuickDeposit i {
    background: url('../images/bank-account.svg') 0 0 no-repeat;
    width: 24px;
    height: 24px;
  }
  .quickDepMobileShow.creditDebitQuickDeposit i {
    background: url('../images/credit-card.svg') 0 0 no-repeat;
    width: 27px;
    height: 24px;
  }
  .quickDepMobileShow.paypalQuickDeposit i {
    background: url('../images/paypal.svg') 0 0 no-repeat;
    width: 28px;
    height: 29px;
  }
  .quickDepMobileShow.helpContentWrap {
    display: flex;
    justify-content: space-between;
    padding: 0 15px 15px;
    list-style-type: none;
  }
  .quickDepMobileShow.helpContentWrap li,
  .quickDepMobileShow.helpContentWrap li a {
    color: #000;
  }
  .quickDepMobileShow.helpContentWrap i,
  .quickDepMobileShow.helpContentWrap span {
    display: inline-block;
  }
  .quickDepMobileShow.helpContentWrap span,
  .quickDepMobileShow.helpContentWrap a {
    font-size: 13px;
    line-height: 16px;
    vertical-align: middle;
  }
  .quickDepMobileShow.helpContentWrap i {
    margin-right: 2px;
    font-size: 16px;
    vertical-align: bottom;
  }
  .quickDepMobileShow.helpContentWrap span,
  .creditDebitCardWrap .cardInfoBox .depositFee,
  .mainPageBtn .depositFee {
    font-family: 'Titillium Web', sans-serif;
  }
  .creditDebitCardWrap.breadCrumbHide {
    padding-top: 49px;
  }
  .pageTitle .quickDepMobileShow.ttFont {
    text-align: center;
    padding-top: 20px;
  }
  .pageTitle .closeBackLink {
    position: fixed;
    left: 0;
    top: 49px;
    z-index: 1;
    height: 30px;
    width: 100%;
    background: #fff;
  }
  .quickDepMobileHide,
  .expiryDateWrap .quickDepMobileHide {
    display: none;
  }
  .pageTitle .closeBackLink .closeLink,
  .pageTitle .closeBackLink .backLink {
    width: 24px;
    height: 24px;
    padding: 2px 10px;
    display: block;
    margin-top: 5px;
  }
  .pageTitle .closeBackLink .closeLink {
    background: url('../images/deposit-close.svg') 0 0 no-repeat;
    margin-right: 10px;
  }
  .pageTitle .closeBackLink .backLink {
    background: url('../images/deposit-back.svg') 0 0 no-repeat;
    margin-left: 10px;
  }
  .payPalMethodWrap .addAmountPaypal {
    padding: 0 15px;
  }
  .payPalMethodWrap .availableBalWrap,
  .cardMethod .availableBalWrap {
    margin: 0 15px 16px;
  }
  ul.paypalInfoList li,
  .creditDebitCardWrap .payPalMethodWrap .paypalInfoWrap .depositFee,
  .creditDebitCardWrap .paypalInfoWrap .updateInfo {
    font-family: 'Titillium Web', sans-serif;
  }
  .addQuickAmountWrap.noFundingOptions .form-group {
    width: 100%;
    float: none !important;
    padding-right: 0;
  }
  .addQuickAmountWrap.noFundingOptions .form-group label {
    top: -14px;
  }
  .addQuickAmountWrap.noFundingOptions .addAmountBox ul,
  .payPalMethodWrap .depositAmountBoxWrap p,
  .cardInfoBoxCnt .expiryDateWrap label {
    display: none;
  }
  .depositMainWrap .depositAmountBoxWrap {
    display: block;
  }
  .creditDebitCardWrap .cardInfoBoxCnt {
    border: none;
    padding: 0;
  }
  .creditDebitCardWrap .cardMethod .cardInfoBox .quickDepInput,
  .creditDebitCardWrap .cardNumberInputWrap input {
    border-radius: 0;
    height: 56px;
    border: 1px solid #ccc;
    font-weight: 400;
    color: #000;
    text-align: left;
    padding-top: 20px;
    font-family: 'Titillium Web', sans-serif;
    font-size: 15px;
    line-height: 24px;
  }
  .creditDebitCardWrap .expiryDateWrap .expiryDateFields:first-child,
  .creditDebitCardWrap .expiryDateWrap .expiryDateFields:last-child {
    border: none;
  }
  .expiryDatecvvWrap .expiryDateWrap {
    width: 70%;
  }
  .creditDebitCardWrap .cvvWrap {
    width: 30%;
  }
  .creditDebitCardWrap .expiryDateWrap .expiryDateFields {
    width: 100%;
  }
  .creditDebitCardWrap .placeHolder,
  .depositMainWrap .placeHolder {
    position: absolute;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    left: 0;
    border: 1px solid #ccc;
    background: #fff;
    padding: 10px;
    font-size: 15px;
    line-height: 34px;
    color: rgb(102, 102, 102);
    display: block;
    font-family: 'Titillium Web', sans-serif;
  }
  .creditDebitCardWrap .expiryDateWrap .expiryDateFields input {
    border: 1px solid #ccc;
  }
  .creditDebitCardWrap .cvvFormInput input {
    padding: 6px 12px;
  }
  .creditDebitCardWrap .quickDepInputWrap.openInput input.quickDepInput {
    border: 1px solid #0066cc;
  }
  .creditDebitCardWrap .quickDepInputWrap.openInput .placeHolder {
    height: auto;
    width: auto;
    top: 5px;
    border: none;
    left: 13px;
    padding: 0;
    font-size: 13px;
    line-height: 16px;
    color: #0066cc;
  }
  .creditDebitCardWrap .cvvWrap input.cvvActualInput {
    border-left: none;
  }
  .creditDebitCardWrap .cardNumberInputWrap .eCard {
    top: 16px;
  }
  .creditDebitCardWrap .cvvWrap input::placeholder,
  .creditDebitCardWrap .depositAmountBoxWrap .form-control::placeholder,
  .creditDebitCardWrap .expiryDateFields input::placeholder,
  .creditDebitCardWrap .cardNumberInputWrap input::placeholder,
  .creditDebitCardWrap.achAccount input::placeholder,
  .creditDebitCardWrap.achAccount .cardInfoBox .form-control::placeholder,
  .creditDebitCardWrap.achAccount .cardInfoBox .form-control {
    color: #666;
    font-weight: 400;
  }
  .markAsDefault.quickDepMobileShow {
    margin-left: 1px;
    margin-bottom: 5px;
  }
  .markAsDefault span {
    display: inline-block;
    vertical-align: middle;
  }
  .markAsDefault span:first-child {
    position: relative;
    margin-right: 4px;
  }
  .markAsDefault span:last-child {
    font-family: 'Titillium Web', sans-serif;
    font-size: 13px;
    line-height: 20px;
    color: #666;
  }
  .creditDebitCardWrap .cardInfoBoxCnt .markAsDefault span input {
    opacity: 0;
    height: 24px;
    width: 24px;
    margin-top: 0;
  }
  .markAsDefault span i {
    position: absolute;
    left: -2px;
    top: 4px;
    color: #0066cc;
  }
  .markAsDefault.checked span i {
    color: #6ba444;
  }
  .cardInfoBox label,
  .withdrawWrapper .mobLabelClass {
    font-size: 15px;
    line-height: 24px;
    color: #000;
    font-weight: 700;
    font-family: 'Titillium Web', sans-serif;
  }
  .creditDebitCardListWrap,
  .creditDebitCardListWrap li,
  .achAccountListWrap,
  .achAccountListWrap li {
    list-style-type: none;
    font-family: 'Titillium Web', sans-serif;
  }
  .creditDebitCardListWrap li,
  .achAccountListWrap li {
    margin-bottom: 8px;
  }
  .creditDebitCardListWrap li .cardNumberInfo,
  .achAccountListWrap li {
    border: 1px solid #ccc;
    border-right: none;
    position: relative;
    width: 75%;
    padding: 8px 8px 6px 63px;
  }
  .achAccountListWrap li {
    width: 100%;
    border-right: 1px solid #ccc;
    padding: 8px 8px 6px 47px;
    cursor: pointer;
  }
  .markUnmarkDefWrap li .cardNumberInfo i,
  .markUnmarkDefWrap li i {
    position: absolute;
    top: 12px;
    left: 9px;
  }
  .creditDebitCardListWrap li .cardNumberInfo i.visa {
    background: url('../images/visa-card.svg') 0 0 no-repeat;
    width: 44px;
    height: 30px;
  }
  .creditDebitCardListWrap li .cardNumberInfo i.masterCard {
    background: url('../images/master-carrd.svg') 0 0 no-repeat;
    width: 46px;
    height: 28px;
  }
  .achAccountListWrap li .bankAccIcon {
    background: url('../images/bank-account.svg') 0 0 no-repeat;
    width: 24px;
    height: 24px;
    left: 10px;
  }
  /* Plaid Funding: Start */
  .achAccountListWrap li .plaidAccIndicator {
    position: absolute;
    left: 179px;
    top: 10px;
  }
  .achAccountListWrap li .plaidAccIndicator svg,
  .achAccountListWrap li .plaidAccIndicator span {
    display: inline-block;
    vertical-align: top;
  }
  .achAccountListWrap li .plaidAccIndicator span {
    font-size: 13px;
    line-height: 16px;
    color: #000;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 400;
  }
  .achAccountListWrap li .plaidAccIndicator svg {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }
  .achAccountListWrap li .plaidAccIndicator.actionReq span {
    color: #ec1c24;
    cursor: pointer;
    margin-top: 1px;
  }
  .achAccountListWrap li .plaidAccIndicator.actionReq {
    top: 8px;
  }
  /* Plaid Funding: End */
  .achAccountListWrap li .accNo,
  .achAccountListWrap li .markDefault,
  .achAccountListWrap li .markedDefault {
    display: block;
  }
  .achAccountListWrap li .accNo {
    font-size: 15px;
    line-height: 20px;
    color: #000;
  }
  .creditDebitCardListWrap li .cardNumberInfo,
  .creditDebitCardListWrap li .cardCvvInfo,
  .markUnmarkDefWrap li .markedDefault i,
  .markUnmarkDefWrap li .markedDefault span,
  .creditDebitCardListWrap li .cardNumberInfo .cardNumber .cardExpDate,
  .markUnmarkDefWrap li span.markDefault,
  .markUnmarkDefWrap li span.markedDefault,
  .loadDefault {
    display: inline-block;
    vertical-align: middle;
  }
  .creditDebitCardListWrap li .cardCvvInfo {
    width: 25%;
    position: relative;
  }
  .creditDebitCardListWrap li .cardCvvInfo .placeHolder {
    width: 97%;
  }
  .creditDebitCardListWrap li .cardCvvInfo input {
    width: 100%;
    height: 56px;
    border: 1px solid #ccc;
    padding: 0 12px;
    color: #000;
  }
  .markUnmarkDefWrap li .markDefault {
    font-size: 13px;
    line-height: 18px;
    color: #0066cc;
    margin-top: 1px;
    cursor: pointer;
  }
  .achAccountListWrap li .markDefault {
    margin-top: -3px;
  }
  .markUnmarkDefWrap li.markedDefaultCheck span.markDefault {
    display: none;
  }
  .markUnmarkDefWrap li.markedDefaultCheck span.markedDefault {
    display: block;
  }
  .markUnmarkDefWrap li.markedDefaultCheck .cardNumberInfo .cardNumber .cardExpDate {
    font-size: 15px;
    line-height: 18px;
  }
  .markUnmarkDefWrap li span.markedDefault {
    color: #669933;
    font-size: 13px;
    margin-top: -1px;
    cursor: pointer;
  }
  .achAccountListWrap.markUnmarkDefWrap li span.markedDefault {
    margin-top: -3px;
  }
  .depositMainWrap .markUnmarkDefWrap li span.markedDefault {
    cursor: default;
  }
  .achAccountListWrap li .markedDefault {
    margin-top: 0;
  }
  .markUnmarkDefWrap li .markedDefault i {
    background: url('../images/done.svg') -3px -3px no-repeat;
    position: static;
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  .markUnmarkDefWrap li .cardNumberInfo .cardNumber .cardExpDate {
    font-size: 13px;
    line-height: 19px;
    color: #0066cc;
    margin-right: 12px;
    cursor: pointer;
  }
  .creditDebitCardListWrap li .cardNumberInfo .cardNo,
  .achAccountListWrap li .accNo {
    font-size: 15px;
    line-height: 20px;
    color: #000;
  }
  .creditDebitCardWrap .accountTypeWrap label.accTypeLabel {
    font-size: 15px;
    line-height: 24px;
    display: block;
    color: #666;
    font-weight: 400;
  }
  .creditDebitCardWrap .accountTypeWrap input[type='radio'],
  .creditDebitCardWrap .accountTypeWrap label span:first-child {
    width: 24px;
  }
  .creditDebitCardWrap .accountTypeWrap label span:first-child {
    font-size: 24px;
  }
  .creditDebitCardWrap .accountTypeWrap label .savingType {
    line-height: 23px;
    color: #000;
    font-weight: 400;
  }
  .quickDepInputWrap {
    position: relative;
  }
  .creditDebitCardWrap .achTerms p,
  .creditDebitCardWrap .achTermLink,
  .explainerText,
  .creditDebitCardWrap .addCardErrorMsg {
    font-family: 'Titillium Web', sans-serif;
  }
  .coachMarkDepWrap {
    position: absolute;
    right: 0;
    top: 16px;
    width: 100%;
  }
  .coachMarkDepWrap .bankAccountFieldHelp {
    position: absolute;
    right: 1px;
    padding: 8px;
    top: -8px;
  }
  .coachMarkDepWrap i {
    font-size: 24px;
    line-height: 24px;
    color: #0066cc;
    display: block;
  }
  .coachMarkContent {
    position: absolute;
    background: #fff;
    width: 100%;
    top: 45px;
    color: #fff;
    filter: drop-shadow(0px 0px 4px #ccc);
    text-align: center;
    border-radius: 2px;
    padding: 0px;
    display: none;
    z-index: 2;
  }
  .coachMarkContent:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: #ffffff transparent;
    border-width: 0 13px 12px;
    top: -9px;
    right: 9px;
    margin-left: -14px;
  }
  .coachMarkContent img {
    max-width: 93%;
    display: block;
    margin: 10px auto;
  }
  .coachMarkDepWrap .bankAccountFieldHelp:hover + .coachMarkContent {
    display: block;
  }
  .pageTitle,
  .explainerText {
    display: none;
  }
  .mobilePageTitle {
    width: 100%;
    background: #fff;
  }
  .mobilePageTitle a {
    color: #000;
    font-size: 24px;
    margin-left: 4px;
    margin-top: 4px;
    text-decoration: none;
  }
  .mobilePageTitle h4 {
    font-size: 21px;
    text-align: center;
    line-height: 28px;
    color: #000;
    padding-bottom: 10px;
    margin: -15px 0 0 0;
    font-weight: 700;
    font-family: 'Titillium Web', sans-serif;
  }
  .creditDebitCardWrap .depositMainWrap .mobilePageTitle h4,
  .iframeClass .mobilePageTitle h4 {
    margin-top: 10px;
  }
  .iframeClass .mobilePageTitle a {
    margin-top: 7px;
    margin-left: 5px;
  }
  .cardMethod .availableBalWrap,
  .payPalMethodWrap .availableBalWrap {
    margin-top: 6px;
  }
  .creditDebitCardWrap .cardInfoBoxCnt input,
  .creditDebitCardWrap .paypalInfoWrap .btnBlue,
  .creditDebitCardWrap .newCardBtn button,
  .creditDebitCardWrap.achAccount .btnGreen {
    height: 48px;
  }
  .withdrawWrapper .pageTitle,
  .creditDebitCardWrap .accountTypeWrap .labelClass.quickDepMobileShow {
    display: block;
  }
  .withdrawWrapper .paymentMethodContent .paymentMethodType {
    display: block;
    width: auto;
  }
  .withdrawWrapper .paymentMethods li.paymentMethod.active {
    border: 1px solid #6a3;
  }
  .transactionViewWrap .helpContentWrap,
  .transactionResult .successIcon.quickDepMobileHide {
    display: none;
  }
  .successIconWrap {
    background: transparent;
    width: auto;
    height: auto;
    margin-bottom: 12px;
  }
  .transactionResult i.quickDepMobileShow {
    font-size: 48px;
    line-height: 48px;
    color: #6ba444;
  }
  .creditDebitCardWrap .transactionResult h2 {
    font-size: 21px;
    line-height: 28px;
    font-weight: 700;
    margin-bottom: 12px;
    padding-bottom: 0;
    color: #000;
  }
  .creditDebitCardWrap .transactionMessage p,
  .creditDebitCardWrap .transactionMessage p.noBalance,
  .creditDebitCardWrap .transactionMessage p:last-child {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    color: #000;
    font-family: 'Titillium Web', sans-serif;
  }
  .creditDebitCardWrap .transactionMessage p strong {
    font-weight: 400;
  }
  .amtAvailInfoWrap {
    padding: 0 15px;
    font-size: 13px;
    line-height: 20px;
    color: #666;
    font-family: 'Titillium Web', sans-serif;
  }
  .amtAvailInfoWrap.noPadding {
    padding: 0;
  }
  .creditDebitCardWrap .creditmarkUnmarkWrap .cvvWrap {
    width: 25%;
  }
  .creditDebitCardWrap .paymentMethod .form-group {
    margin-bottom: 8px;
  }
  .creditDebitCardWrap .addCardErrorMsg {
    margin-bottom: 0;
  }
  .creditDebitCardWrap .cardInfoBox .depositFee,
  .mainPageBtn .depositFee {
    font-size: 13px;
    line-height: 20px;
    color: #666;
  }
  .cardListSection .expiryDateWrap {
    width: 75%;
  }
  .depositMainWrap .paymentMethodContent .paymentMethods li.paymentMethod:hover {
    border: 1px solid #0066cc;
  }
  .creditDebitCardWrap .paymentMethod input[disabled] {
    border: none;
  }
  .enterDepositAmount input.addAmount:-webkit-autofill,
  .enterDepositAmount input.addAmount:-webkit-autofill:hover,
  .enterDepositAmount input.addAmount:-webkit-autofill:focus,
  .enterDepositAmount input.addAmount:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }
  .creditDebitCardWrap .achTermLink {
    margin-top: 0;
  }
  .creditDebitCardWrap .cardInfoBox .depositFee,
  .mainPageBtn .depositFee {
    margin: 0 0 16px 0;
  }
  .creditDebitCardWrap .payPalMethodWrap .amtAvailable.depositFee.quickDepMobileShow {
    padding: 0 15px;
    font-family: 'Titillium Web', sans-serif;
    font-size: 13px;
    line-height: 20px;
    color: #666;
  }
  .creditDebitCardWrap .newCardBtn.quickDepAchBtn {
    margin-bottom: 15px;
  }
  .iframeClass .creditDebitCardWrap .availableBalWrap {
    margin-top: 6px;
  }
  .iframeClass .creditDebitCardWrap .depositMainWrap .availableBalWrap {
    margin-top: 6px;
  }
  .iframeClass .creditDebitCardWrap .depositMainWrap .mobilePageTitle {
    height: auto;
  }
  .depositAmountBoxWrap.inputFocused .addAmountBox {
    border-color: #0066cc;
  }
  .depositAmountBoxWrap.inputFocused .addAmountBox label {
    color: #0066cc;
  }
  .creditDebitCardWrap .expiryDatecvvWrap.addCardViewWrap .expiryDateWrap.cvvFocused .quickDepInputWrap .placeHolder,
  .creditDebitCardWrap .expiryDatecvvWrap.addCardViewWrap .expiryDateWrap.cvvFocused .quickDepInputWrap .quickDepInput {
    border-right: none;
  }
  .creditDebitCardWrap .expiryDatecvvWrap.addCardViewWrap .cvvWrap .quickDepInput,
  .creditDebitCardWrap .expiryDatecvvWrap.addCardViewWrap .cvvWrap .placeHolder,
  .creditDebitCardWrap .creditDebitCardListWrap .cardListSection .cvvWrap.expiryFocused .quickDepInputWrap .placeHolder,
  .creditDebitCardWrap
    .creditDebitCardListWrap
    .cardListSection
    .cvvWrap.expiryFocused
    .quickDepInputWrap
    .quickDepInput {
    border-left: none;
  }
  .creditDebitCardWrap .quickDepInputWrap .form-control {
    height: 56px;
  }
  .creditDebitCardWrap .newCardBtn .addNewBtn {
    margin-bottom: 16px;
    line-height: 24px;
    height: 36px;
  }
  .markUnmarkDefWrap li .cardNumberInfo .cardNumber .enterExpDate {
    height: 20px;
    padding: 0;
    width: 38%;
    border: none;
    color: #0066cc;
    font-size: 13px;
    margin-right: 2px;
  }
  .availableBalWrap.balLoad {
    height: 42px;
    background: #fff;
    border: none;
  }
  .creditDebitCardWrap .cardInfoBoxCnt .depositBtn .btns,
  .creditDebitCardWrap .newCardBtn .addNewBtn,
  .paymentMethod .btnBlue {
    font-size: 16px;
  }
  .stickyBottomWrap,
  .stickyHeadWrap {
    position: fixed;
    width: 100%;
    left: 0;
    background: #fff;
    z-index: 1;
  }
  .stickyBottomWrap {
    padding: 8px 0 14px;
    bottom: -20px;
    transform: translate3d(0, 0, 0);
  }
  .stickyHeadWrap {
    top: 49px;
  }
  .stickyHeadWrap .amtAvailInfoWrap {
    margin-top: 22px;
  }
  .stickyBottomWrap form {
    margin-left: 15px;
    margin-right: 15px;
  }
  .stickyBottomWrap .amtAvailable.depositFee {
    padding-left: 15px;
    padding-right: 15px;
  }
  .col-sm-9.cardMethod {
    padding-bottom: 90px;
  }
  .col-sm-9.cardMethod.cardOptionWrap {
    padding-bottom: 125px;
  }
  .col-sm-9.cardMethod.accountOptionWrap {
    padding-bottom: 90px;
  }
  .col-sm-9.cardMethod.depBoxInputErr .stickyHeadWrap .amtAvailInfoWrap {
    margin-top: 0;
  }
  .cardMethod.cardOptionWrap .markAsDefault {
    margin-bottom: 15px;
    display: inline-block;
    cursor: pointer;
  }
  .depositAmtBox .addCardErrorMsg.quickDepMobileShow {
    padding: 0 15px;
    margin-top: -7px;
  }
  .creditDebitCardWrap .cardInfoBox,
  .expiryDatecvvWrap.addCardViewWrap {
    margin-bottom: 0;
  }
  .col-sm-9.cardMethod.accountOptionWrap .noteAddress {
    margin-bottom: 25px;
    margin-top: 0;
  }
  .stickyBottomWrap .errMsg {
    font-size: 12px;
    font-family: 'Titillium Web', sans-serif;
    padding: 0px 15px;
    margin-bottom: 5px;
    margin-top: -10px;
  }
  .stickyHeadWrap,
  .iframeClass .stickyHeadWrap {
    position: static;
  }
  .col-sm-9.cardMethod,
  .col-sm-9.cardMethod.depBoxInputErr,
  .col-sm-9.cardMethod.weeklyAmtAvail,
  .col-sm-9.cardMethod.weeklyAmtAvail.depBoxInputErr,
  .creditDebitCardWrap .depositMainWrap,
  .iframeClass .col-sm-9.cardMethod,
  .iframeClass .col-sm-9.cardMethod.depBoxInputErr,
  .iframeClass .col-sm-9.cardMethod.weeklyAmtAvail,
  .iframeClass .col-sm-9.cardMethod.weeklyAmtAvail.depBoxInputErr,
  .iframeClass .creditDebitCardWrap .depositMainWrap,
  .iframeClass .col-sm-9.cardMethod.payPalOptionWrap {
    padding-top: 55px;
  }
  .iframeClass .depositMainWrap .mobilePageTitle h4 {
    margin-top: 26px;
  }
  .mobilePageTitle.stickyTitle {
    position: fixed;
    top: 49px;
    left: 0;
    width: 100%;
    z-index: 1;
    background: #fff;
  }
  .depositAmountBoxWrap {
    margin-bottom: 16px;
  }
  .col-sm-9.cardMethod .amtAvailInfoWrap p {
    margin-bottom: 0;
  }
  .col-sm-9.cardMethod .cardInfoBox {
    margin-top: 22px;
  }
  .depositAmtBox .addCardErrorMsg.quickDepMobileShow {
    margin-top: -15px;
  }
  .creditDebitCardWrap .depositMainWrap .availableBalWrap {
    margin-left: 0;
    margin-right: 0;
  }
  .iframeClass .mobilePageTitle.stickyHeadTitle {
    display: none;
  }
  .iosAppClass .mobilePageTitle.stickyTitle {
    top: 0;
  }
  .iosAppClass .col-sm-9.cardMethod,
  .iosAppClass .col-sm-9.depositMainWrap {
    padding-top: 10px;
  }
  .iosAppClass .stickyBottomWrap .helpContentWrap li:last-child {
    display: none;
  }
  .paypalInfoWrap {
    margin-top: 30px;
  }
  .col-sm-9.cardMethod.depBoxInputErr .paypalInfoWrap {
    margin-top: 0;
  }
  .cardListSection .cardExpDateInfoWrap {
    position: relative;
  }
  .cardListSection .cardExpDateInfoWrap .addCardErrorMsg {
    position: absolute;
    top: 27px;
    left: 0;
  }
  .creditDebitCardListWrap .cardListSection.expiryError {
    margin-bottom: 30px;
  }
  .creditDebitCardListWrap .cardListSection.expiryError.onlyExpiryError {
    margin-bottom: 8px;
  }
  .creditDebitCardListWrap .cardListSection.expiryClicked .cardNumberInfo {
    border: 1px solid #06c;
  }
  .cardMethod .cardInfoBox .creditDebitCardListWrap .cardListSection.expiryClicked .cvvWrap .quickDepInput {
    border-left: none;
  }
  .col-sm-9.cardMethod.cardOptionWrap.noDepositFeeClass {
    padding-bottom: 95px;
  }
  .creditDebitCardListWrap .cardListSection.fieldFocused .cardNumberInfo::before,
  .achAccountListWrap li.active::before {
    content: ' ';
    width: 3px;
    height: 56px;
    position: absolute;
    left: -1px;
    top: -1px;
    background: #06c;
  }
  .cardMethod .errMsg.alert.quickDepMobileShow,
  .depositMainWrap .errMsg.alert.quickDepMobileShow {
    margin-bottom: 0;
    padding-top: 0;
    font-family: 'Titillium Web', sans-serif;
  }
  .depositMainWrap .errMsg.alert.quickDepMobileShow {
    padding-left: 0;
    padding-right: 0;
  }
  .errMsg {
    color: #ec1c24;
  }
  .creditDebitCardWrap .quickDepInputWrap.noFocus input.quickDepInput {
    border-color: #ccc;
  }
  .creditDebitCardWrap .expiryDatecvvWrap.addCardViewWrap .cvvWrap .quickDepInputWrap.openInput .quickDepInput {
    border-left: 1px solid #06c;
  }
  .markUnmarkDefWrap li i.loadDefault {
    background: url('../images/load-default.svg') 0 0 no-repeat;
    width: 24px;
    height: 24px;
    margin-top: -7px;
    position: static;
    animation: rotation 2s infinite linear;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-359deg);
    }
  }
  .depositMainWrap .depositAmountBoxWrap {
    margin-right: 0;
    margin-left: 0;
  }
  .depositMainWrap .amtAvailInfoWrap {
    padding: 0;
    margin-bottom: 20px;
  }
  .depositMainWrap .depositAmountBoxWrap .addAmountBox .form-group {
    margin-bottom: 8px;
  }
  .stickyBottomWrap.mainPageBtn .depositBtn {
    margin: 0 15px 15px;
  }
  .stickyBottomWrap.mainPageBtn .btnGreen {
    display: block;
    font-size: 16px;
    height: 48px;
    font-weight: 700;
    width: 100%;
  }
  .depositMainWrap .markUnmarkDefWrap {
    margin-bottom: 16px;
  }
  .depositMainWrap .cvvWrap input.cvvActualInput {
    height: 56px;
    border-radius: 0;
    color: #000;
    text-align: left;
  }
  .cardListSection.expiryClicked .cvvWrap .placeHolder {
    border-left: none;
  }
  .creditDebitCardWrap .cardNumberInputWrap .eCard.master-card {
    background: url('../images/master-carrd.svg') 0 0 no-repeat;
    width: 46px;
    height: 28px;
  }
  .creditDebitCardWrap .cardNumberInputWrap .eCard.master-card {
    top: 14px;
  }
  .creditDebitCardWrap .cardNumberInputWrap .eCard.visa {
    background: url('../images/visa-card.svg') 0 0 no-repeat;
    width: 44px;
    height: 30px;
  }
  .creditDebitCardWrap .cardNumberInputWrap .eCard.visa {
    top: 13px;
  }
  .creditDebitCardWrap .paypalInfoWrap .updateInfo p {
    cursor: pointer;
    color: #005bef;
  }
  .creditDebitCardWrap .paypalInfoWrap .updateInfo p strong {
    font-weight: 400;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .creditDebitCardWrap .paypalInfoWrap .firstOptionShow {
    position: relative;
    margin: 35px 0 8px 14px;
  }
  .creditDebitCardWrap .paypalInfoWrap .firstOptionShow:before {
    content: ' ';
    background: #6a2;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    position: absolute;
    left: -14px;
    top: 4px;
  }
  /* Quick Deposit View: End */

  /* Withdraw for IOS App: Start */
  .paymentMethodWrap.withdrawWrapper.iosAppClass {
    padding-bottom: 25px;
  }
  .paymentMethodWrap.withdrawWrapper.iosAppClass .proceedBtn {
    margin-bottom: 0;
  }
  .paymentMethodWrap.withdrawWrapper .pageTitle {
    padding: 10px 0;
  }
  .paymentMethodWrap.withdrawWrapper .pageTitle h2 {
    padding-left: 0;
  }
  .paymentMethodWrap.withdrawWrapper.iosAppClass .pageTitle h2 {
    text-align: center;
  }
  .paymentMethodWrap.withdrawWrapper.iosAppClass .pageTitle {
    padding-top: 15px;
  }
  /* Withdraw for IOS App: End*/

  /* Withdraw Main View: Start */
  .withdrawWrapper {
    padding-bottom: 10px;
    padding-top: 98px;
  }
  .paymentMethodWrap.withdrawWrapper {
    background: #fff;
  }
  .withdrawWrapper.mainWithdrawWrap {
    padding-top: 110px;
  }
  .withdrawWrapper .paymentMethods {
    margin-bottom: 16px;
  }
  .withdrawWrapper .paymentMethods li.paymentMethod {
    box-shadow: none;
    border: 1px solid #ccc;
    margin-bottom: 12px;
  }
  .withdrawWrapper .paymentMethod.addCardForm {
    padding-bottom: 68px;
  }
  .withdrawBreadCrumb,
  .withdrawWrapper .pageTitle,
  .withdrawWrapper .additionalMethods .note,
  .withdrawWrapper .additionalMethods .additionalMethodWrapper,
  .withdrawWrapper .chequeIssue,
  .withdrawWrapper .additionMethodTitle,
  .withdrawWrapper .mobileHide,
  .withdrawWrapper .paymentMethodType .methodName i.icon {
    display: none;
  }
  .withdrawWrapper .mobilePageTitle {
    padding-top: 20px;
    z-index: 2;
  }
  .withdrawWrapper .mobilePageTitle h4 {
    margin: 0;
  }
  ul.paymentMethods {
    padding-top: 16px;
    border-top: 1px solid #ccc;
  }
  .withdrawWrapper .availableBalWrap {
    display: block;
    padding-top: 12px;
    margin-top: 0;
  }
  .withdrawWrapper .paymentRadioBtn {
    display: none !important;
  }
  .withdrawWrapper .desktopHide {
    display: block;
  }
  .withdrawWrapper .paymentMethodType .methodDesc {
    font-family: 'Titillium Web', sans-serif;
  }
  .withdrawWrapper .methodName .witdrawIcons,
  .withdrawWrapper .methodName .withdrawMainLabel {
    cursor: pointer;
  }
  .withdrawWrapper .witdrawIcons {
    position: absolute;
    top: 14px;
    left: 14px;
  }
  .withdrawWrapper .payPalOption.paymentMethod .withdrawPaypal {
    margin-left: 0;
  }
  .withdrawWrapper .paymentMethods li.paymentMethod.xpressbetCheck .witdrawIcons {
    left: 10px;
  }
  .withdrawWrapper .paymentMethods li.paymentMethod.withdrawPaypal .witdrawIcons {
    left: 11px;
  }
  .withdrawWrapper .paymentMethodContent .paymentMethodType .methodName,
  .withdrawWrapper .paymentMethodContent .paymentMethodType p {
    margin-bottom: 0;
  }
  .withdrawWrapper .withdrawMainLabel,
  .withdrawWrapper .paymentMethodContent .paymentMethodType p,
  .withdrawMoney p {
    font-size: 13px;
    line-height: 16px;
    font-family: 'Titillium Web', sans-serif;
  }
  .withdrawWrapper .withdrawMoney .iconSvg-PopOut g > g {
    stroke: #06c;
  }
  .withdrawWrapper .proceedBtn input {
    font-weight: 700;
    font-size: 16px;
    background: #06c;
    line-height: 18px;
    font-family: 'Titillium Web', sans-serif;
  }
  .withdrawWrapper .proceedBtn input:hover {
    background: #19345e;
    box-shadow: 0 4px 8px -2px rgba(25, 52, 94, 0.7);
  }
  .withdrawWrapper .mainContentWrap {
    padding-bottom: 0;
  }
  .withdrawWrapper .btnBlue.withdrawBtn {
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    background-color: #0066cc;
    padding: 16px 0;
    font-size: 13px;
    line-height: 16px;
    font-weight: 700;
  }
  .withdrawWrapper input.btnBlue.withdrawBtn[disabled] {
    background: #e3e3e3;
  }
  .withdrawWrapper .withdrawBtn:hover {
    background: #19345e;
    box-shadow: 0 4px 8px -2px rgba(25, 52, 94, 0.7);
  }
  .iframeClass .withdrawWrapper {
    padding-top: 66px;
  }
  .iframeClass .withdrawWrapper .availableBalWrap {
    margin-top: 0;
  }
  .iosAppClass.withdrawWrapper {
    padding-top: 68px;
  }
  .iosAppClass .mobilePageTitle,
  .iosAppClass.withdrawWrapper .withdrawMoney {
    display: none;
  }
  .iosAppClass.mainWithdrawWrap {
    padding-top: 49px;
  }
  .withdrawWrapper.mainWithdrawWrap .paymentMethods li.paymentMethod:hover {
    border: 1px solid #06c;
  }
  /* Withdraw Main View: End */

  /* Withdraw ACH View: Start */
  .withdrawWrapper .userDetails {
    display: none;
  }
  .achAccount.withdrawWrapper .achAccountListWrap svg {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 24px;
  }
  .achAccount.withdrawWrapper .achAccountListWrap li {
    padding: 14px 8px 14px 63px;
    margin-bottom: 12px;
  }
  .achAccount.withdrawWrapper .achAccountListWrap li span {
    font-size: 15px;
    line-height: 26px;
    color: #000;
    font-family: 'Titillium Web', sans-serif;
  }
  .withdrawWrapper .mobLabelClass {
    margin-bottom: 8px;
    display: block;
  }
  .bankWithdrawWrap .mobilePageTitle,
  .checkRequestWithdrawWrap .mobilePageTitle,
  .paypalWithdrawWrap .mobilePageTitle {
    padding-top: 0;
  }
  .bankWithdrawWrap .mobilePageTitle h4,
  .checkRequestWithdrawWrap .mobilePageTitle h4,
  .paypalWithdrawWrap .mobilePageTitle h4 {
    margin-top: -15px;
  }
  .withdrawWrapper .paymentInfo .availableBalBox {
    display: none !important;
  }
  .withdrawWrapper .paymentInfo .balShow {
    display: block !important;
  }
  .bankWithdrawWrap .col-sm-6,
  .bankWithdrawWrap .paymentMethod.addCardForm > .row,
  .paypalWithdrawWrap .col-sm-12,
  .paypalWithdrawWrap .paymentMethod.addCardForm > .row,
  .checkRequestWithdrawWrap .col-sm-6,
  .checkRequestWithdrawWrap .paymentMethod.addCardForm > .row {
    padding-left: 0;
    padding-right: 0;
  }
  .paypalWithdrawWrap .availableBalWrap {
    margin-left: 0;
    margin-right: 0;
  }
  .withdrawAmtWrap {
    padding-top: 16px;
    margin-top: 16px;
    border-top: 1px solid #ccc;
  }
  .withdrawAmtWrap .relative {
    position: relative;
  }
  .withdrawAmtWrap .relative.active .mobileLabel {
    color: #06c;
  }
  .withdrawAmtWrap .relative.active input {
    border: 1px solid #06c;
  }
  .withdrawAmtWrap input.form-control {
    height: 56px;
    border-radius: 0;
    border: 1px solid #ccc;
    padding: 23px 12px 12px 20px;
  }
  .withdrawAmtWrap input.form-control:focus {
    border: 1px solid #06c;
  }
  .withdrawAmtWrap input.form-control,
  .withdrawAmtWrap .inputMobLabel {
    font-size: 15px;
    line-height: 24px;
    font-family: 'Titillium Web', sans-serif;
    color: #000;
  }
  .withdrawWrapper.bankWithdrawWrap .achAccountListWrap li.active:before {
    content: '';
    background: transparent;
    width: 0;
  }
  .withdrawWrapper.bankWithdrawWrap .achAccountListWrap li.active {
    border: 1px solid #06c;
  }
  .withdrawAmtWrap .mobileLabel {
    font-size: 13px;
    line-height: 16px;
    color: #666;
    font-weight: 400;
    top: 8px;
    left: 12px;
    font-family: 'Titillium Web', sans-serif;
    position: absolute;
  }
  .withdrawAmtWrap .inputMobLabel {
    position: absolute;
    top: 22px;
    left: 12px;
  }
  .withdrawWrapper .errMsg,
  .withdrawWrapper .addCardErrorMsg,
  .withdrawWrapper .alert-danger {
    color: #ec1c24;
    font-family: 'Titillium Web', sans-serif;
    margin: 4px 0 4px 0;
    background: transparent;
    padding: 0;
    font-size: 12px;
    border: none;
  }
  .withdrawWrapper .errMsg {
    font-size: 14px;
  }
  .withdrawWrapper .alert-danger {
    margin-bottom: 10px;
  }
  .withdrawWrapper.checkRequestWithdrawWrap .mainContentWrap,
  .paypalWithdrawWrap.withdrawWrapper .mainContentWrap {
    margin-top: 10px;
  }
  .withdrawWrapper.bankWithdrawWrap .achAccountListWrap li,
  .mobilePageTitle a {
    cursor: pointer;
  }
  .withdrawWrapper .paymentMethods li.paymentMethod.xpressbetAc.disabled {
    pointer-events: none;
    cursor: default;
    border: 1px solid #ccc;
  }
  .withdrawWrapper .paymentMethods li.paymentMethod.xpressbetAc.disabled .withdrawMainLabel,
  .withdrawWrapper .paymentMethods li.paymentMethod.xpressbetAc.disabled .methodDesc {
    color: #ccc;
    -webkit-text-fill-color: #ccc;
  }
  .withdrawWrapper .paymentMethods li.paymentMethod.xpressbetAc.disabled svg path {
    fill: #ccc;
  }
  .withdrawWrapper .paymentMethods li.paymentMethod.xpressbetAc label,
  .withdrawWrapper .paymentMethods li.paymentMethod.xpressbetAc span.withdrawMainLabel {
    color: #06c;
    -webkit-text-fill-color: #06c;
    -webkit-opacity: 1;
    opacity: 1;
  }
  .withdrawWrapper .paymentMethods li.paymentMethod.xpressbetAc p.methodDesc {
    color: #666;
    -webkit-text-fill-color: #666;
    -webkit-opacity: 1;
    opacity: 1;
  }
  /* Withdraw ACH View: End */

  /* Withdraw CheckRequest View: Start */
  .checkRequestWithdrawWrap .paymentSummary {
    display: none;
  }
  .checkReqAddrList li {
    border: 1px solid #ccc;
    margin-bottom: 12px;
    padding: 8px 12px;
    list-style-type: none;
  }
  .checkReqAddrList li:last-child,
  .checkReqAddrList,
  .withdrawWrapper.bankWithdrawWrap .achAccountListWrap li:last-child,
  .achAccountListWrap ul {
    margin-bottom: 0;
  }
  .achAccountListWrap,
  .checkReqList {
    margin-top: -5px;
  }

  .checkReqAddrList li.active {
    border: 1px solid #06c;
  }
  .checkReqAddrList li.active .mobileLabel {
    color: #06c;
  }
  .checkReqAddrList li label {
    font-size: 13px;
    line-height: 1.23;
    font-family: 'Titillium Web', sans-serif;
    color: #666;
    display: block;
    margin-bottom: 4px;
  }
  .checkReqAddrList li p {
    font-size: 15px;
    line-height: 1.6;
    font-family: 'Titillium Web', sans-serif;
    color: #000;
    margin-bottom: 0;
  }
  .paymentMethod.addCardForm > .row.checkReqHide {
    padding: 0;
  }
  .withdrawMainBtn {
    position: fixed;
    bottom: 50px;
    padding: 10px 15px 5px 15px;
    width: 100%;
    left: 0;
    background: #fff;
  }
  /* Withdraw CheckRequest View: End */

  /* Withdraw Paypal View: Start */
  .paypalWithdrawWrap .updateInfo,
  .paypalWithdrawWrap .firstOptionShow div {
    font-family: 'Titillium Web', sans-serif;
  }
  .paypalWithdrawWrap .firstOptionShow div {
    position: relative;
    margin-left: 14px;
    margin-bottom: 8px;
  }
  .paypalWithdrawWrap .firstOptionShow div:before {
    content: ' ';
    background: #6a2;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    position: absolute;
    left: -14px;
    top: 4px;
  }
  .paypalWithdrawWrap .mobilePaypalTxt {
    margin-top: 12px;
    margin-bottom: 0;
  }
  .paypalWithdrawWrap .actionLink {
    margin-bottom: 0;
  }
  .paypalWithdrawWrap .updateInfoText {
    line-height: 20px;
  }
  .withdrawWrapper .paymentMethod.addCardForm.payPalMethodWrap {
    padding-bottom: 80px;
  }
  .paypalWithdrawWrap .actionLink {
    font-size: 13px;
    line-height: 16px;
    color: #06c;
  }
  .paymentMethod.addCardForm > .row.paypalRow {
    padding-bottom: 0;
  }
  .addCardForm.payPalMethodWrap .updateInfo,
  paypalWithdrawWrap .firstOptionShow div,
  .paypalWithdrawWrap .mobilePaypalTxt {
    font-size: 13px;
    line-height: 16px;
    color: #666;
    font-family: 'Titillium Web', sans-serif;
  }
  .checkRequestWithdrawWrap .paymentMethod.addCardForm .withdrawRow,
  .paypalWithdrawWrap .paymentMethod.addCardForm .withdrawRow,
  .iframeClass .checkRequestWithdrawWrap .paymentMethod.addCardForm .withdrawRow,
  .iframeClass .bankWithdrawWrap .paymentMethod.addCardForm .withdrawRow {
    padding-top: 0;
  }
  .iframeClass .bankWithdrawWrap .mainContentWrap,
  .iframeClass .checkRequestWithdrawWrap .mainContentWrap,
  .iframeClass .paypalWithdrawWrap .mainContentWrap {
    margin-top: 0;
  }
  .payPalOptionWrap .updateInfo .paypalInfoList {
    margin-top: 30px;
  }
  .withdrawWrapper .withdrawAmtWrap .relative.errInput input {
    border: 1px solid #ec1c24;
  }
  /* Withdraw Paypal View: End */
  .rushFieldWrap {
    padding-left: 0;
    padding-right: 0;
    position: relative;
    z-index: 0;
    margin-bottom: 10px;
  }
  .addCardForm .rushFieldWrap .defaultSelect {
    height: 56px;
    padding-top: 22px;
    border-radius: none;
    border: 1px solid #ccc;
  }
  .rushFieldWrap select,
  .rushFieldWrap select option,
  .rushFieldWrap select option span,
  .rushFeeWrap {
    color: #000;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 400;
  }
  .rushFieldWrap label {
    position: absolute;
    font-size: 13px;
    line-height: 1.23;
    font-family: Titillium Web, sans-serif;
    color: #666;
    font-weight: 400;
    top: 8px;
    left: 12px;
    z-index: 3;
    margin-bottom: 0;
  }
  .rushFieldWrap.active .defaultSelect {
    border-color: #06c;
  }
  .rushFieldWrap.active label {
    color: #06c;
  }
  .rushFeeWrap {
    color: #666;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .mainContentWrap {
    border: none !important;
  }
  .paymentMethodWrap .pageTitle {
    padding: 10px 0 20px 0;
    margin: 0;
  }
  .paymentMethods .cardPaymentActions {
    margin-top: 45px !important;
  }
  .paymentMethods .cardMethod .paymentMethodType {
    width: 60% !important;
  }
  .paymentSummary li.row {
    margin: 0 0 10px 0;
  }
  .paymentSummary li.row > div {
    padding: 0;
  }
  .tabletHide {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .mainContentWrap {
    border: 1px solid #e9e9e9;
    padding: 0 15px;
  }
  .paymentMethods .cardMethod .paymentMethodType {
    width: 63%;
  }
  .paymentMethods .cardMethod .paymentMethodType {
    width: 63%;
    float: left;
    margin-left: 10px;
  }
  .paymentMethods .cardPaymentActions {
    margin-top: 15px;
    text-align: right;
    float: right;
  }
  .paymentMethod {
    padding-right: 25px;
    border-right: 1px solid #e4e4e4;
  }
  .paymentMethod .form-group {
    margin-bottom: 0px;
  }
  .paymentSummary {
    padding: 10px;
  }
}

@media only screen and (min-width: 767px) {
  .paymentMethod .form-group.addressWrap,
  .paymentMethod .form-group.rushWrap {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .mobileHide {
    display: none;
  }
  .paymentMethodWrap {
    background: #f5f5f5;
  }
  .proceedBtn .btns {
    width: 100%;
  }
  .paymentMethodWrap .pageTitle {
    margin: 0 0 15px 0;
    padding: 10px 0 0 10px;
  }
  .paymentMethodWrap .pageTitle h2 {
    font-size: 18px;
  }
  .paymentMethods li.paymentMethod {
    padding: 13px 4px;
    background: #fff;
  }
  .paymentMethods .paymentMethodType .methodDesc {
    padding: 0;
  }
  .paymentMethods .cardPaymentActions {
    margin: 15px 0 0 30px;
  }
  .paymentMethod .btnBlue {
    width: 100%;
  }
  .paymentMethod .form-group {
    margin-bottom: 3px;
  }
  .paymentSummary {
    padding: 10px;
    margin-bottom: 15px;
  }
  .paymentMethod .payPalAmountField,
  .paymentMethod.addCardForm > .row {
    background: #fff;
    padding: 10px;
    margin: 0;
  }
  .transactionWrap {
    padding: 0 0 75px 0;
  }
  .transactionWrap .transactionActions .btns {
    width: 130px;
  }
  .addCardForm .amtAvailable {
    padding: 20px 0;
  }

  .mobileMenubar .mobileMenuDropdown .mobileLogoutWrap {
    background: #f2f2f2;
    padding: 10px;
  }
  .paymentMethod .paymentMethodType.xpressPaymentMethodType {
    width: 80%;
  }
}
@media only screen and (max-width: 480px) {
  .creditDebitCardWrap.achAccount .pageTitle span {
    width: 72%;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2 / 1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  [class^='icon-'],
  [class*=' icon-'],
  .fancybox-close {
    background-image: url('../images/sprite@2x.png');
    background-size: 120px auto;
  }
}
